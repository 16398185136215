import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ObjetoPreciosos } from '../models/formulario/secoes/objeto-preciosos';

@Injectable({
  providedIn: 'root'
})
export class ObjetoPreciososService {
  private readonly objetoUrl = `${environment.url}/objetos`;
  private readonly formUrl = `${environment.url}/formulario`;

  constructor(
    private http: HttpClient,
  ) { }

  saveAll(formularioId: any, form: ObjetoPreciosos[]): Observable<ObjetoPreciosos[]> {
    return this.http.post<ObjetoPreciosos[]>(`${this.formUrl}/${formularioId}/objetos-list`, form).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  updateAll(formularioId: any, form: ObjetoPreciosos[]): Observable<ObjetoPreciosos[]> {
    return this.http.put<ObjetoPreciosos[]>(`${this.formUrl}/${formularioId}/objetos-list`, form).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  deleteById(id: any): Observable<number> {
    return this.http.delete<number>(`${this.objetoUrl}/${id}`).pipe(
        take(1),
        catchError(this.handleError)
    );
}



  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error.error);
    }
  }

}
