import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {environment} from 'src/environments/environment';
import {PagedResponse} from '../models/formulario/PagedResponse';
import {Authentication} from '../models/logs/authentication';

@Injectable({
  providedIn: 'root'
})
export class HistoricoService {
  private readonly url = `${environment.url}/log`;

  constructor(private http: HttpClient) {
  }

  getAll(page: number, size: number): Observable<PagedResponse<Authentication>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());
    return this.http.get<PagedResponse<Authentication>>(`${this.url}/authentication`, {params});
  }

  getAllUsuario(page: number, size: number): Observable<any> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());
    return this.http.get(`${this.url}/authentication/usuario`, {params});
  }

  getAllDeclarante(page: number, size: number): Observable<any> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());
    return this.http.get(`${this.url}/authentication/declarante`, {params});
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error.error);
    }
  }
}
