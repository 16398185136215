import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ComerciaisProprio } from '../models/formulario/secoes/participacoes/comerciais-proprio';

@Injectable({
  providedIn: 'root'
})
export class ComerciaisProprioService {
  private readonly comerciaisProprioUrl = `${environment.url}/comerciais-proprio`;
  private readonly formUrl = `${environment.url}/formulario`;

constructor(
  private http: HttpClient,
) { }

saveAll(formularioId: any, form: ComerciaisProprio[]): Observable<ComerciaisProprio[]> {
  return this.http.post<ComerciaisProprio[]>(`${this.formUrl}/${formularioId}/comerciais-proprio-list`, form).pipe(
    take(1),
    catchError(this.handleError)
  );
}

updateAll(formularioId: any, form: ComerciaisProprio[]): Observable<ComerciaisProprio[]> {
  return this.http.put<ComerciaisProprio[]>(`${this.formUrl}/${formularioId}/comerciais-proprio-list`, form).pipe(
    take(1),
    catchError(this.handleError)
  );
}

deleteById(id: any): Observable<number> {
  return this.http.delete<number>(`${this.comerciaisProprioUrl}/${id}`).pipe(
    take(1),
    catchError(this.handleError)
  );
}

private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error.error);
    }
  }

}
