import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {navItems_Pt, navItems_Tp} from '../../../containers/navigations/declarante_nav';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../../core/services';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  user = null;

  constructor(
    private location: Location,
    public translate: TranslateService,
    private authService: AuthenticationService,
  ) {
  }

  ngOnInit(): void {
    this.user = this.authService.currentUserValue;
  }

  languageOnChange(event: any): void {
    switch (event.value) {
      case 'tp':
        this.translate.use(event.value);
        break;
      case 'pt':
        this.translate.use(event.value);
        break;
    }
  }

  back(): void {
    this.location.back();
  }

  setMatCardStyle(user: any): any {
    if (user) {
      return {
        'max-width': '60em',
        'background-color': '#FFFFFF',
      };
    } else {
      return {
        'max-width': '60em',
        color: '#FFFFFF',
        background: 'rgba(97, 82, 82, 0.45)',
        'border-radius': '16px',
        'backdrop-filter': 'blur(10px)',
        ' -webkit-backdrop-filter': 'blur(10px)'
      };
    }
  }

}
