import {Component, ElementRef, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'body',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    // tslint:disable-next-line:variable-name
    private _elementRef: ElementRef
  ) {
  }

  // tslint:disable-next-line: typedef
  ngOnInit() {
    this._elementRef.nativeElement.removeAttribute('ng-version');
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
