import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, take} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {AgregadoFamiliar} from '../models/agregado-familiar';
import {SaldoBancario} from '../models/formulario/secoes/bancario/saldo-bancario';
import {Dividas} from '../models/formulario/secoes/dividas';
import {InvestimentoInterposta} from '../models/formulario/secoes/investimento/investimento-interposta';
import {InvestimentoProprio} from '../models/formulario/secoes/investimento/investimento-proprio';
import {ObjetoPreciosos} from '../models/formulario/secoes/objeto-preciosos';
import {ComerciaisInterposta} from '../models/formulario/secoes/participacoes/comerciais-interposta';
import {ComerciaisProprio} from '../models/formulario/secoes/participacoes/comerciais-proprio';
import {PatrimonioImobiliario} from '../models/formulario/secoes/patrimonio-imobiliario';
import {PrendasRecebidas} from '../models/formulario/secoes/prendas-recebidas';
import {Rendimentos} from '../models/formulario/secoes/rendimentos/rendimentos';
import {Veiculo} from '../models/formulario/secoes/veiculo';
import {ViagensDespesas} from '../models/formulario/secoes/viagens-despesas';
import {PagedResponse} from '../models/formulario/PagedResponse';

@Injectable({
  providedIn: 'root'
})
export class AgregadoFamiliarService {
  private readonly url = `${environment.url}/agregado-familiar`;

  constructor(private http: HttpClient) {
  }

  getAll(page: number, size: number): Observable<PagedResponse<AgregadoFamiliar>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());
    return this.http.get<PagedResponse<AgregadoFamiliar>>(`${this.url}`, {params});
  }

  getById(id: any): Observable<AgregadoFamiliar> {
    return this.http.get<AgregadoFamiliar>(`${this.url}/${id}`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  deleteById(id: any): Observable<number> {
    return this.http.delete<AgregadoFamiliar>(`${this.url}/${id}`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  saveRendimento(agregadoId: any, formularioId: any, form: Rendimentos): Observable<Rendimentos> {
    return this.http.post<Rendimentos>(`${this.url}/${agregadoId}/formulario/${formularioId}/rendimento`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  savePatrimonioList(agregadoId: number, formularioId: number, form: any[]): Observable<PatrimonioImobiliario[]> {
    return this.http.post<PatrimonioImobiliario[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/patrimonios-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updatePatrimonioList(agregadoId: number, formularioId: number, form: any[]): Observable<PatrimonioImobiliario[]> {
    return this.http.put<PatrimonioImobiliario[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/patrimonios-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  saveVeiculoList(agregadoId: number, formularioId: number, form: any[]): Observable<Veiculo[]> {
    return this.http.post<Veiculo[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/veiculos-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updateVeiculoList(agregadoId: number, formularioId: number, form: any[]): Observable<Veiculo[]> {
    return this.http.put<Veiculo[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/veiculos-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  saveObjetoList(agregadoId: number, formularioId: number, form: any[]): Observable<ObjetoPreciosos[]> {
    return this.http.post<ObjetoPreciosos[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/objetos-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updateObjetoList(agregadoId: number, formularioId: number, form: any[]): Observable<ObjetoPreciosos[]> {
    return this.http.put<ObjetoPreciosos[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/objetos-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  saveInvestimentoProprioList(agregadoId: number, formularioId: number, form: any[]): Observable<InvestimentoProprio[]> {
    return this.http.post<InvestimentoProprio[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/investimento-proprio-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updateInvestimentoProprioList(agregadoId: number, formularioId: number, form: any[]): Observable<InvestimentoProprio[]> {
    return this.http.put<InvestimentoProprio[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/investimento-proprio-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  saveInvestimentoInterpostaList(agregadoId: number, formularioId: number, form: any[]): Observable<InvestimentoInterposta[]> {
    return this.http.post<InvestimentoInterposta[]>(
      `${this.url}/${agregadoId}/formulario/${formularioId}/investimento-interposta-list`,
      form
    )
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updateInvestimentoInterpostaList(agregadoId: number, formularioId: number, form: any[]): Observable<InvestimentoInterposta[]> {
    return this.http.put<InvestimentoInterposta[]>(
      `${this.url}/${agregadoId}/formulario/${formularioId}/investimento-interposta-list`,
      form
    )
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }


  saveComerciaisProprioList(agregadoId: number, formularioId: number, form: any[]): Observable<ComerciaisProprio[]> {
    return this.http.post<ComerciaisProprio[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/comerciais-proprio-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updateComerciaisProprioList(agregadoId: number, formularioId: number, form: any[]): Observable<ComerciaisProprio[]> {
    return this.http.put<ComerciaisProprio[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/comerciais-proprio-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  saveComerciaisInterpostaList(agregadoId: number, formularioId: number, form: any[]): Observable<ComerciaisInterposta[]> {
    return this.http.post<ComerciaisInterposta[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/comerciais-interposta-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updateComerciaisInterpostaList(agregadoId: number, formularioId: number, form: any[]): Observable<ComerciaisInterposta[]> {
    return this.http.put<ComerciaisInterposta[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/comerciais-interposta-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  saveSaldoBancarioList(agregadoId: number, formularioId: number, form: any[]): Observable<SaldoBancario[]> {
    return this.http.post<SaldoBancario[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/saldo-bancario-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updateSaldoBancarioList(agregadoId: number, formularioId: number, form: any[]): Observable<SaldoBancario[]> {
    return this.http.put<SaldoBancario[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/saldo-bancario-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  saveDividasList(agregadoId: number, formularioId: number, form: any[]): Observable<Dividas[]> {
    return this.http.post<Dividas[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/dividas-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updateDividasList(agregadoId: number, formularioId: number, form: any[]): Observable<Dividas[]> {
    return this.http.put<Dividas[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/dividas-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  savePrendasRecebidasList(agregadoId: number, formularioId: number, form: any[]): Observable<PrendasRecebidas[]> {
    return this.http.post<PrendasRecebidas[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/prendas-recebidas-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updatePrendasRecebidasList(agregadoId: number, formularioId: number, form: any[]): Observable<PrendasRecebidas[]> {
    return this.http.put<PrendasRecebidas[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/prendas-recebidas-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  saveViagensDespesasList(agregadoId: number, formularioId: number, form: any[]): Observable<ViagensDespesas[]> {
    return this.http.post<ViagensDespesas[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/viagens-despesas-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updateViagensDespesasList(agregadoId: number, formularioId: number, form: any[]): Observable<ViagensDespesas[]> {
    return this.http.put<ViagensDespesas[]>(`${this.url}/${agregadoId}/formulario/${formularioId}/viagens-despesas-list`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error.error);
    }
  }

}
