import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComprovanteComponent } from './comprovante/comprovante.component';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { FormularioDetailComponent } from './formulario-detail/formulario-detail.component';
import { MatButtonModule } from '@angular/material/button';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatTableModule } from '@angular/material/table';
import { VerificationDialogComponent } from './verification-dialog/verification-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ResumoComponent } from './resumo/resumo.component';
import { CdkColumnDef } from '@angular/cdk/table';
import { MatDividerModule } from '@angular/material/divider';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { FaqComponent } from './faq/faq.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { PrintPageComponent } from './print-page/print-page.component';



@NgModule({
  declarations: [
    ComprovanteComponent,
    FormularioDetailComponent,
    VerificationDialogComponent,
    ResumoComponent,
    FaqComponent,
    PrintPageComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatTooltipModule,
    MatIconModule,
    PipesModule,
    MatButtonModule,
    NgxSpinnerModule,
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    MatDividerModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatExpansionModule,
  ],
  exports: [
    ComprovanteComponent,
    FormularioDetailComponent,
    VerificationDialogComponent,
    ResumoComponent,
  ],
  providers: [CdkColumnDef]
})
export class SharedModule { }
