import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private location: Location,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const user = this.authService.currentUserValue;

        if (user) {
            return true;
        } else {
            this.location.back();
            return false;
        }
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        const user = this.authService.currentUserValue;
        const allowedRoles: any[] = childRoute.data.role;

        if (!allowedRoles || allowedRoles.length === 0) {
            // role not authorised so redirect to home page
            this.location.back();
            return false;
        } else if (allowedRoles.includes(user.role)) {
            return true;
        } else {
            this.location.back();
            return false;
        }
    }
}
