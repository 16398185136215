import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Role} from '../enums/role.enum';
import {AuthenticationService} from '../services';

@Injectable({providedIn: 'root'})
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const user = this.authService.currentUserValue;
    if (user) {
      if (user.role === Role.declarante) {
        this.router.navigate(['/declarante/inicio']).then();
        return false;
      } else {
        this.router.navigate(['/admin/painel']).then();
        return false;
      }
    }
    return true;
  }
}
