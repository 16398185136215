export enum Secao {
  rendimentos = 'RENDIMENTOS',
  patrimonios = 'PATRIMONIOS',
  veiculos = 'VEICULOS',
  objetos = 'OBJETOS',
  investimentos_proprio = 'INVESTIMENTOS_PROPRIO',
  investimentos_interposta = 'INVESTIMENTOS_INTERPOSTA',
  participacoes_proprio = 'PARTICIPACOES_PROPRIO',
  participacoes_interposta = 'PARTICIPACOES_INTERPOSTA',
  saldos_bancarios = 'SALDOS_BANCARIO',
  dividas = 'DIVIDAS',
  prendas = 'PRENDAS',
  viagens = 'VIAGENS',
  membro_integrante = 'MEMBRO_INTEGRANTE',
  participacao_organizacao = 'PARTICIPACAO_ORGANIZACAO',
}
