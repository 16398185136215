import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {Periodo} from 'src/app/core/enums/periodo.enum';
import {Role} from 'src/app/core/enums/role.enum';
import {Step} from 'src/app/core/enums/step.enum';
import {AgregadoFamiliar} from 'src/app/core/models/agregado-familiar';
import {Forms} from 'src/app/core/models/formulario/forms';
import {Rendimentos} from 'src/app/core/models/formulario/secoes/rendimentos/rendimentos';
import {AuthenticationService, DeclaranteService, FormularioService} from 'src/app/core/services';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {Documento} from '../../../core/models/documento';
import {Secao} from '../../../core/enums/secao.enum';
import {Estado} from '../../../core/enums/estado.enum';


@Component({
  selector: 'app-formulario-detail',
  templateUrl: './formulario-detail.component.html',
  styleUrls: ['./formulario-detail.component.css']
})
export class FormularioDetailComponent implements OnInit, OnDestroy {
  private subscription$!: Subscription;
  formulario!: Forms;
  declaranteId!: number;
  @ViewChild('formularioPDF') formularioPDF!: ElementRef;
  dataSourceAgregado: any;
  dataSourceEntidadeEmprestimo: any;
  agregadoFamiliarList!: AgregadoFamiliar[];
  agragadoColumns: string[];
  entidadeColumns: string[];
  rendimentos!: Rendimentos;
  stepChecked = Step.checked;
  rendimentoDocList: Documento[] = [];
  patrimonioDocList: Documento[] = [];
  veiculoDocList: Documento[] = [];
  objetoDocList: Documento[] = [];
  investimentoDocList: Documento[] = [];
  investimentoInterpostaDocList: Documento[] = [];
  participacoesDocList: Documento[] = [];
  participacoesInterpostaDocList: Documento[] = [];
  saldoDocList: Documento[] = [];
  dividasDocList: Documento[] = [];
  prendasDocList: Documento[] = [];
  viagensDocList: Documento[] = [];
  membroDocList: Documento[] = [];
  participacaoDocList: Documento[] = [];
  isDownloading = false;
  inicial = Periodo.inicial;
  anual = Periodo.anual;
  final = Periodo.final;
  posExercicio = Periodo.posExercicio;
  triAnual = Periodo.trianual;
  adHoc = Periodo.adHoc;
  iniciativa = Periodo.mudancaSubstancial;


  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private formularioService: FormularioService,
    private declaranteService: DeclaranteService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) {
    this.agragadoColumns = ['index', 'nomeCompleto', 'relacao', 'estadoCivil', 'tipoDocumento', 'numeroDocumento', 'entidade', 'atividade'];
    this.entidadeColumns = ['nomeCompleto', 'tipoDocumento', 'numeroDocumento'];
  }

  ngOnInit(): void {
    this.spinner.show();
    const formularioId = this.route.snapshot.params.formId;
    const role = this.route.snapshot.data.role;

    if (role.includes(Role.super_admin) || role.includes(Role.admin)) {
      this.declaranteId = this.route.snapshot.params.declaranteId;
      this.getAllRequest(formularioId, this.declaranteService.getFormularioById(this.declaranteId, formularioId));
    } else if (role === Role.declarante) {
      this.declaranteId = this.authService.currentUserValue.id;
      this.getAllRequest(formularioId, this.declaranteService.getFormularioById(this.declaranteId, formularioId));
    }
  }

  ngOnDestroy(): void {
    this.subscription$?.unsubscribe();
  }

  getAllRequest(formId: any, getFormSrv: Observable<Forms>): void {
    const getFormById = getFormSrv;
    const getDeclaranteById = this.declaranteService.getById(this.declaranteId);
    const getAgregadoFormularioList = this.declaranteService.getFormularioAgregadoFamiliarList(this.declaranteId, formId);

    forkJoin([getFormById, getDeclaranteById, getAgregadoFormularioList])
      .subscribe({
        next: value => { 
          this.formulario = value[0];
          this.formulario.declarante = value[1];
          this.dataSourceAgregado = new MatTableDataSource(value[2]);
          this.agregadoFamiliarList = value[2];

          if (this.formulario.periodo !== Periodo.mudancaSubstancial) {
            this.rendimentos = this.formulario.rendimentoList[0];
            this.dataSourceEntidadeEmprestimo = new MatTableDataSource(this.rendimentos.entidadeEmprestimoList);
          }
          this.spinner.hide()
        },
        error: err => this.spinner.hide()
      });
  }

  checkFormularioByEstado(formulario: Forms): boolean {
    return formulario.estado === Estado.submetido ? true : false;
  }

  mapDocumentos(documentoList: Documento[]): void {
    documentoList.forEach(value => {
      switch (value.secao) {
        case Secao.rendimentos:
          this.rendimentoDocList.push(value);
          break;
        case Secao.patrimonios:
          this.patrimonioDocList.push(value);
          break;
        case Secao.veiculos:
          this.veiculoDocList.push(value);
          break;
        case Secao.objetos:
          this.objetoDocList.push(value);
          break;
        case Secao.investimentos_proprio:
          this.investimentoDocList.push(value);
          break;
        case Secao.investimentos_interposta:
          this.investimentoInterpostaDocList.push(value);
          break;
        case Secao.participacoes_proprio:
          this.participacoesDocList.push(value);
          break;
        case Secao.participacoes_interposta:
          this.participacoesInterpostaDocList.push(value);
          break;
        case Secao.saldos_bancarios:
          this.saldoDocList.push(value);
          break;
        case Secao.dividas:
          this.dividasDocList.push(value);
          break;
        case Secao.prendas:
          this.prendasDocList.push(value);
          break;
        case Secao.viagens:
          this.viagensDocList.push(value);
          break;
        case Secao.membro_integrante:
          this.membroDocList.push(value);
          break;
        case Secao.participacao_organizacao:
          this.participacaoDocList.push(value);
          break;
      }
    });
  }

  downloadFile(documento: Documento): void {
    this.isDownloading = true;
    this.declaranteService.downloadDocumento(this.declaranteId, documento.path)
      .subscribe({
        next: (data) => {
          this.isDownloading = false;
          const blob = new Blob([data], {type: 'application/pdf'});
          const downloadURL = window.URL.createObjectURL(blob);
          window.open(downloadURL, '_blank');
        },
        error: (error) => {
          this.isDownloading = false;
        }
      });
  }

  printPage() {
    this.router.navigate(
      [`/print`], {
      state: {
        formulario: this.formulario,
        agregadoFamiliar: this.agregadoFamiliarList
      }
    }
    )
      .then(() => {this.spinner.hide()});
  }
}
