import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexLayoutComponent, DeclaranteLayoutComponent, AdminLayoutComponent } from './containers';
import { Role } from './core/enums/role.enum';
import { AuthenticationGuard } from './core/security/authentication.guard';
import { LoginGuard } from './core/security/login.guard';
import { LoginComponent } from './views/admin/login/login.component';
import {FaqComponent} from './views/shared/faq/faq.component';
import { FormularioDetailComponent } from './views/shared/formulario-detail/formulario-detail.component';
import { PrintPageComponent } from './views/shared/print-page/print-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'declarante',
    pathMatch: 'full',
  },
  {
    path: '',
    component: IndexLayoutComponent,
    data: {
      title: 'Login Page'
    },
    canActivate: [LoginGuard],
    children: [
      {
        path: 'faq',
        component: FaqComponent,
      },
      {
        path: 'declarante',
        loadChildren: () => import('./views/index/index.module').then(m => m.IndexModule)
      },
      {
        path: 'admin',
        redirectTo: 'admin/login',
      },
      {
        path: 'admin/login',
        component: LoginComponent,
        canActivate: [LoginGuard]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    data: {
      title: 'Admin'
    },
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: 'admin',
        loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule)
      }
    ]
  },
  {
    path: '',
    component: DeclaranteLayoutComponent,
    data: {
      title: 'Declarante'
    },
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: 'declarante',
        loadChildren: () => import('./views/declarante/declarante.module').then(m => m.DeclaranteModule)
      },
      {
        path: 'declarante/agregado-familiar',
        loadChildren: () => import('./views/agregado-familiar/agregado-familiar.module').then(m => m.AgregadoFamiliarModule)
      },
    ]
  },
  {
    path: 'print',
    component: PrintPageComponent,
    canActivate: [AuthenticationGuard],
  },
  { path: '**', redirectTo: 'declarante' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
