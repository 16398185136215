import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Declarante } from '../models/declarante';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AgregadoFamiliar } from '../models/agregado-familiar';
import { Forms } from '../models/formulario/forms';
import { Estado } from '../enums/estado.enum';

@Injectable({ providedIn: 'root' })
export class DeclaranteService {
  // tslint:disable-next-line:variable-name
  private readonly _url = `${environment.url}/declarants`;

  constructor(private http: HttpClient) {
  }


  get url(): string {
    return this._url;
  }

  save(form: any): Observable<Declarante> {
    return this.http.post<Declarante>(`${this._url}/`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  getAll(): Observable<Declarante[]> {
    return this.http.get<Declarante[]>(`${this._url}/`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  getById(id: any): Observable<Declarante> {
    return this.http.get<Declarante>(`${this._url}/${id}`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updateById(id: number, form: any): Observable<Declarante> {
    return this.http.put<Declarante>(`${this._url}/${id}`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updatePassword(form: any): Observable<Declarante> {
    return this.http.put<Declarante>(`${this._url}/credentials`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  passwordAuthentication(id: any, form: any): Observable<Declarante> {
    return this.http.post<Declarante>(`${this._url}/authenticate/${id}`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  saveFormulario(declaranteId: any, form: any): Observable<Forms> {
    return this.http.post<Forms>(`${this._url}/${declaranteId}/formulario`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updateFormulario(declaranteId: any, formularioId: any, form: any): Observable<Forms> {
    return this.http.put<Forms>(`${this._url}/${declaranteId}/formulario/${formularioId}`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  getFormularioEditById(declaranteId: any, formularioId: any): Observable<Forms> {
    return this.http.get<Forms>(`${this.url}/${declaranteId}/formulario/edit/${formularioId}`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  getFormularioById(declaranteId: any, formularioId: any): Observable<Forms> {
    return this.http.get<Forms>(`${this._url}/${declaranteId}/formulario/${formularioId}`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  getFormularioByNumeroFormulario(declaranteId: any, numeruFormulario: string): Observable<Forms> {
    return this.http.get<Forms>(`${this._url}/${declaranteId}/formulario/${numeruFormulario}/comprovante`)
    .pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  getFormularioByEstado(declaranteId: any, estado: Estado, page: number, size: number): Observable<Forms[]> {
    const params = new HttpParams()
      .set('estado', estado)
      .set('page', page.toString())
      .set('size', size.toString());
    return this.http.get<Forms[]>(`${this._url}/${declaranteId}/formulario`, { params }).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  deleteFormularioById(declaranteId: any, formularioId: any): Observable<number> {
    return this.http.delete<number>(`${this._url}/${declaranteId}/formulario/${formularioId}`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  activate(form: any): Observable<Declarante> {
    return this.http.put<any>(`${this._url}/activate`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  resetPassword(form: any): Observable<any> {
    return this.http.put<any>(`${this._url}/credentials/reset`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  resetPasswordLink(form: any): Observable<any> {
    return this.http.post<any>(`${this._url}/reset-password`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  validateToken(token: string): Observable<any> {
    return this.http.get<any>(`${this._url}/validate-token/${token}`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  getAgregadoFamiliarList(id: any): Observable<AgregadoFamiliar[]> {
    return this.http.get<AgregadoFamiliar[]>(`${this._url}/${id}/agregado-familiar`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  /**
   * Get Declarante's Agregado Familiar along with their formulario (Rendimento, Patrimonio, etc.)
   * @param declaranteId number
   * @param formularioId number
   * @returns List of Agregado Familiar
   */
  getFormularioAgregadoFamiliarList(declaranteId: any, formularioId: any): Observable<AgregadoFamiliar[]> {
    return this.http.get<AgregadoFamiliar[]>(`${this._url}/${declaranteId}/formulario/${formularioId}/agregado-familiar`).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  saveAgregadoFamilia(declaranteId: any, form: any): Observable<AgregadoFamiliar> {
    return this.http.post<AgregadoFamiliar>(`${this._url}/${declaranteId}/agregado-familiar`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updateAgregadoFamilia(declaranteId: any, agregadoId: any, form: any): Observable<AgregadoFamiliar> {
    return this.http.put<AgregadoFamiliar>(`${this._url}/${declaranteId}/agregado-familiar/${agregadoId}`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  uploadDocumento(declaranteId: any, formData: FormData): Observable<Declarante> {
    return this.http.post<Declarante>(`${this._url}/${declaranteId}/upload`, formData)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteDocumentoById(declaranteId: any, fileId: any): Observable<number> {
    return this.http.delete<number>(`${this._url}/${declaranteId}/file/${fileId}`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  downloadDocumento(declaranteId: any, path: string): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get<Blob>(`${this._url}/${declaranteId}/download?documentPath=${path}`, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error.error);
    }
  }
}
