<mat-card *ngIf="!(formulario && formulario.declarante)" id="spinner">
  <ngx-spinner [fullScreen]="false" bdColor="rgb(22, 20, 20, 0.5)" color="#ffff" type="ball-clip-rotate-pulse">
  </ngx-spinner>
</mat-card>
<mat-card #formularioPDF *ngIf="formulario && formulario.declarante" id="formularioPDF">
  <div style="page-break-after: always">

    <!-- Header -->
    <div class="row">
      <div class="col-md-3 d-flex justify-content-center">
        <img alt="Logo RDTL" height="120" src="/assets/images/cac.png" width="120">
      </div>
      <div class="col-md-8 text-center pt-4">
        <h4>
          REPÚBLICA DEMOCRÁTICA DE TIMOR-LESTE
        </h4>
        <h4>
          COMISSÃO ANTI-CORRUPÇÃO
        </h4>
        <h4 *ngIf="formulario.periodo === inicial">
          {{"formDetail.formType.inicial" |translate}}
        </h4>
        <h4 *ngIf="formulario.periodo === anual">
          {{"formDetail.formType.anual" |translate}}
        </h4>
        <h4 *ngIf="formulario.periodo === final">
          {{"formDetail.formType.final" |translate}}
        </h4>
        <h4 *ngIf="formulario.periodo === posExercicio">
          {{"formDetail.formType.posExercicio" |translate}}
        </h4>
        <h4 *ngIf="formulario.periodo === iniciativa">
          {{"formDetail.formType.iniciativa" |translate}}
        </h4>
        <h4 *ngIf="formulario.periodo === triAnual">
          {{"formDetail.formType.triAnual" |translate}}
        </h4>
        <h4 *ngIf="formulario.periodo === adHoc">
          {{"formDetail.formType.adHoc" |translate}}
        </h4>
      </div>
      <div class="col-md-1 icon-group">
        <button (click)="printPage()" matTooltip="Exportar para PDF" [matTooltipPosition]="'above'"
          class="mx-md-2 mx-lg-4" mat-icon-button color="warn">
          <mat-icon>picture_as_pdf</mat-icon>
      </button>
      </div>
    </div>

    <!-- sub header -->
    <div class="row mt-5 bg-subheader text-white">
      <div class="col-5 pt-1 text-center">
        <span>
          Norma de Origem: Lei nº 7/2020, de 26 de agosto.</span>
      </div>
      <div class="col-7 pt-1 text-center">
        <span>
          Publicação: Jornal da República Série I – Nr. 35
        </span>
      </div>
    </div>

    <!-- 1 Identificacao -->
    <div class="card mt-lg-4 mt-md-4 mt-sm-2">
      <div class="card-header">
        <h4 class="card-title">
          {{"formDetail.identificationDeclarant.title" | translate}}
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <h5>
              {{"formDetail.identificationDeclarant.documentType" | translate}}
            </h5>
            <h5 class="font-weight-bolder">
              {{formulario.declarante.tipoDocumento |titlecase}}
            </h5>
          </div>
          <div class="col-md-6">
            <h5>
              {{"formDetail.identificationDeclarant.documentNumber" | translate}}
            </h5>
            <h5 class="font-weight-bolder">
              {{formulario.declarante.numeroDocumento}}
            </h5>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <h5>
              {{"formDetail.identificationDeclarant.fullName" | translate}}
            </h5>
            <h5 class="font-weight-bolder">
              {{formulario.declarante.nomeCompleto |titlecase}}
            </h5>
          </div>
          <div class="col-md-6">
            <h5>
              {{"formDetail.identificationDeclarant.institution" | translate}}
            </h5>
            <h5 class="font-weight-bolder">
              {{formulario.cargo.instituicao.nome}}
            </h5>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <h5>
              {{"formDetail.identificationDeclarant.gender" | translate}}
            </h5>
            <h5 class="font-weight-bolder">
              {{formulario.declarante.sexo}}
            </h5>
          </div>
          <div class="col-md-6">
            <h5>
              {{"formDetail.identificationDeclarant.role" | translate}}
            </h5>
            <h5 class="font-weight-bolder">
              {{formulario.cargo.nome}}
            </h5>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-6">
            <h5>
              {{"formDetail.identificationDeclarant.status" | translate}}
            </h5>
            <h5 class="font-weight-bolder">
              {{formulario.declarante.estadoCivil}}
            </h5>
          </div>
          <div class="col-md-6">
            <h5>
              {{"formDetail.identificationDeclarant.year" | translate}}
            </h5>
            <h5 class="font-weight-bolder">
              {{formulario.anoDeclaracao}}
            </h5>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="formulario.periodo === 'MUDANCA_SUBSTANCIAL'">
      <div class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h5 class="card-title">
            {{"formDetail.substancial.title" | translate}}
          </h5>
        </div>
        <div class="card-body">
          <div [innerHTML]="formulario.mudancaSubstancial"></div>
        </div>
      </div>
    </div>

    <div *ngIf="formulario.periodo !== 'MUDANCA_SUBSTANCIAL'">
      <!-- 2. Agregado Familiar -->
      <div *ngIf="formulario.declarante.agregadoFamiliarList.length > 0"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.identificationFamily.title" | translate}}
          </h4>
        </div>
        <div class="card-body">
          <mat-table [dataSource]="dataSourceAgregado">
            <ng-container matColumnDef="index">
              <mat-header-cell *matHeaderCellDef class="w-75" mat-sort-header> Nº</mat-header-cell>
              <mat-cell *matCellDef="let i = index" class="w-75">
                <span class="mobile-label">Nº :</span>
                {{i + 1}}
              </mat-cell>
            </ng-container>


            <ng-container matColumnDef="nomeCompleto">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{"formDetail.identificationFamily.fullName" | translate}}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span class="mobile-label">{{"formDetail.identificationFamily.fullName" | translate}} :</span>
                {{element.nomeCompleto |titlecase}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="relacao">
              <mat-header-cell *matHeaderCellDef class="w-100" mat-sort-header>
                {{"formDetail.identificationFamily.relationship" | translate}}
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="w-100">
                <span class="mobile-label">{{"formDetail.identificationFamily.relationship" | translate}} :</span>
                {{element.relacao |titlecase}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="estadoCivil">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{"formDetail.identificationFamily.status" | translate}}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span class="mobile-label">{{"formDetail.identificationFamily.status" | translate}} :</span>
                {{element.estadoCivil |titlecase}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="tipoDocumento">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{"formDetail.identificationFamily.documentType" | translate}}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span class="mobile-label">{{"formDetail.identificationFamily.documentType" | translate}} :</span>
                {{element.tipoDocumento}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="numeroDocumento">
              <mat-header-cell *matHeaderCellDef>
                {{"formDetail.identificationFamily.documentNumber" | translate}}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span class="mobile-label">{{"formDetail.identificationFamily.documentNumber" | translate}} :</span>
                {{element.numeroDocumento}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="entidade">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{"formDetail.identificationFamily.entity" | translate}}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span class="mobile-label">{{"formDetail.identificationFamily.entity" | translate}} :</span>
                {{element.entidadeExerceFuncoes}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="atividade">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{"formDetail.identificationFamily.activity" | translate}}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span class="mobile-label">{{"formDetail.identificationFamily.activity" | translate}} :</span>
                {{element.atividadeProfessional}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="agragadoColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: agragadoColumns; let i = index"></mat-row>
          </mat-table>
        </div>
      </div>

      <!-- 3. Rendimentos -->
      <div class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.rendimentos.title" | translate}}
          </h4>
        </div>
        <div class="card-body">
          <h4>
            Mensal
          </h4>
          <div class="row mt-3">
            <div class="col-md-3">
              <h5>
                {{"formDetail.rendimentos.salary" | translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.salariosMensal| currency }}</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.rendimentos.honorarios" | translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.honorariosMensal | currency}}</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.rendimentos.pensoes" | translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.pensoesMensal | currency}}</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.rendimentos.rendas" | translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.rendasMensal | currency}}</h5>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-3">
              <h5>
                {{"formDetail.rendimentos.lucros" | translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.lucrosMensal| currency }}</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.rendimentos.juros" | translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.jurosMensal | currency}}</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.rendimentos.compensacoes" | translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.compensacoesMensal| currency}}</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.rendimentos.receitas" | translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.receitasVendasMensal | currency}}</h5>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-4">
              <h5>
                {{"formDetail.rendimentos.jogosFortunas" | translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.ganhosJogosFortunaMensal| currency }}</h5>
            </div>
            <div class="col-md-4">
              <h5>
                {{"formDetail.rendimentos.heranca" | translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.valoresPatrimoniosMensal| currency}}</h5>
            </div>
            <div class="col-md-4">
              <h5>
                {{"formDetail.rendimentos.emprestimos.value" | translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.emprestimosContraidosMensal| currency}}</h5>
            </div>

          </div>

          <mat-divider></mat-divider>
          <h4 class="mt-3">
            Anual
          </h4>
          <div class="row mt-3">
            <div class="col-md-3">
              <h5>
                {{"formDetail.rendimentos.salary"|translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.salariosAnual| currency }}</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.rendimentos.honorarios"|translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.honorariosAnual | currency}}</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.rendimentos.pensoes"|translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.pensoesAnual | currency}}</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.rendimentos.rendas"|translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.rendasAnual | currency}}</h5>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-3">
              <h5>
                {{"formDetail.rendimentos.lucros"|translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.lucrosAnual| currency }}</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.rendimentos.juros"|translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.jurosAnual | currency}}</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.rendimentos.compensacoes"|translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.compensacoesAnual| currency}}</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.rendimentos.receitas"|translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.receitasVendasAnual | currency}}</h5>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-4">
              <h5>
                {{"formDetail.rendimentos.jogosFortunas"|translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.ganhosJogosFortunaAnual| currency }}</h5>
            </div>
            <div class="col-md-4">
              <h5>
                {{"formDetail.rendimentos.heranca"|translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.valoresPatrimoniosAnual | currency}}</h5>
            </div>
            <div class="col-md-4">
              <h5>
                {{"formDetail.rendimentos.emprestimos.value"|translate}}
              </h5>
              <h5 class="font-weight-bolder">{{rendimentos.emprestimosContraidosAnual| currency}}</h5>
            </div>

          </div>

          <div *ngIf="rendimentos.entidadeEmprestimoList.length>0" class="d-flex justify-content-center mt-5">
            <h5>
              {{"formDetail.rendimentos.emprestimos.title"|translate}}
            </h5>
          </div>
          <div *ngIf="rendimentos.entidadeEmprestimoList.length>0" class="row mt-1">
            <div class="col-12">
              <mat-table [dataSource]="dataSourceEntidadeEmprestimo">
                <ng-container matColumnDef="index">
                  <mat-header-cell *matHeaderCellDef class="w-75" mat-sort-header> Nº</mat-header-cell>
                  <mat-cell *matCellDef="let i = index" class="w-75">
                    <span class="mobile-label">Nº :</span>
                    {{i + 1}}
                  </mat-cell>
                </ng-container>


                <ng-container matColumnDef="nomeCompleto">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{"formDetail.rendimentos.emprestimos.fullName"|translate}}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <span class="mobile-label">{{"formDetail.rendimentos.emprestimos.fullName"|translate}} :</span>
                    {{element.nome |titlecase}}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="tipoDocumento">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{"formDetail.rendimentos.emprestimos.documentType"|translate}}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <span class="mobile-label">{{"formDetail.rendimentos.emprestimos.documentType"|translate}} :</span>
                    {{element.tipoDocumento}}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="numeroDocumento">
                  <mat-header-cell *matHeaderCellDef>
                    {{"formDetail.rendimentos.emprestimos.documentNumber"|translate}}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <span class="mobile-label">{{"formDetail.rendimentos.emprestimos.documentNumber"|translate}} :</span>
                    {{element.numeroDocumento}}
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="entidadeColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: entidadeColumns; let i = index"></mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </div>

      <!-- 3.1 RENDIMENTOS DO MEMBRO DO AGREGADO FAMILIAR -->
      <div *ngIf="formulario.formularioSteps.checkedAgregadoRendimentos === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.rendimentos.columns.title"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div class="row border-bottom border-black">
            <div class="col-md-1">
              <h5>Nº</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.rendimentos.columns.fullName"|translate}}
              </h5>
            </div>
            <div class="col-md-8">
              <h5>
                {{"formDetail.rendimentos.columns.type"|translate}}
              </h5>
            </div>
          </div>
          <div *ngFor="let agregadoFamiliar of agregadoFamiliarList; let i = index;"
               class="row">
            <div *ngIf="agregadoFamiliar.rendimentos !== null" class="col-12">
              <div class="row border-bottom border-black pt-3">
                <div class="col-md-1">
                  <h5>{{i + 1}}.</h5>
                </div>
                <div class="col-md-3">
                  <h5 class="font-weight-bolder">{{agregadoFamiliar.nomeCompleto}}</h5>
                </div>
                <div class="col-md-8">
                  <h4>
                    Mensal
                  </h4>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.rendimentos.salary"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.salariosMensal| currency }}</h5>
                      <h5>
                        {{"formDetail.rendimentos.pensoes"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.pensoesMensal | currency}}</h5>
                      <h5>
                        {{"formDetail.rendimentos.lucros"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.lucrosMensal| currency }}</h5>
                      <h5>
                        {{"formDetail.rendimentos.compensacoes"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.compensacoesMensal| currency}}</h5>
                      <h5>
                        {{"formDetail.rendimentos.jogosFortunas"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.ganhosJogosFortunaMensal| currency }}</h5>
                      <h5>
                        {{"formDetail.rendimentos.emprestimos.value"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">
                        {{agregadoFamiliar.rendimentos.emprestimosContraidosMensal| currency}}
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.rendimentos.honorarios"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.honorariosMensal | currency}}</h5>
                      <h5>
                        {{"formDetail.rendimentos.rendas"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.rendasMensal | currency}}</h5>
                      <h5>
                        {{"formDetail.rendimentos.juros"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.jurosMensal | currency}}</h5>
                      <h5>
                        {{"formDetail.rendimentos.receitas"|translate}}
                      </h5>
                      <h5
                        class="font-weight-bolder">{{agregadoFamiliar.rendimentos.receitasVendasMensal | currency}}</h5>
                      <h5>
                        {{"formDetail.rendimentos.heranca"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.valoresPatrimoniosMensal | currency}}</h5>
                    </div>
                  </div>
                  <mat-divider id="custom"></mat-divider>
                  <h4 class="mt-3">
                    Anual
                  </h4>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.rendimentos.salary"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.salariosAnual| currency }}</h5>
                      <h5>
                        {{"formDetail.rendimentos.pensoes"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.pensoesAnual | currency}}</h5>
                      <h5>
                        {{"formDetail.rendimentos.lucros"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.lucrosAnual| currency }}</h5>
                      <h5>
                        {{"formDetail.rendimentos.compensacoes"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.compensacoesAnual| currency}}</h5>
                      <h5>
                        {{"formDetail.rendimentos.jogosFortunas"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.ganhosJogosFortunaAnual| currency }}</h5>
                      <h5>
                        {{"formDetail.rendimentos.emprestimos.value"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">
                        {{agregadoFamiliar.rendimentos.emprestimosContraidosAnual| currency}}
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.rendimentos.honorarios"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.honorariosAnual | currency}}</h5>
                      <h5>
                        {{"formDetail.rendimentos.rendas"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.rendasAnual| currency}}</h5>
                      <h5>
                        {{"formDetail.rendimentos.juros"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.jurosAnual | currency}}</h5>
                      <h5>
                        {{"formDetail.rendimentos.receitas"|translate}}
                      </h5>
                      <h5
                        class="font-weight-bolder">{{agregadoFamiliar.rendimentos.receitasVendasAnual | currency}}</h5>
                      <h5>
                        {{"formDetail.rendimentos.heranca"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{agregadoFamiliar.rendimentos.valoresPatrimoniosAnual | currency}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="rendimentoDocList.length > 0" class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            ANEXOS DO RENDIMENTO
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <mat-progress-bar *ngIf="isDownloading" mode="indeterminate"></mat-progress-bar>
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th scope="col">Arquivo</th>
                  <th scope="col">Tamanho</th>
                  <th scope="col">Ação</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of rendimentoDocList">
                  <td>
                    {{ item.fileName }}
                  </td>
                  <td>{{ item.size / 1024 / 1024 | number: '.2' }} MB</td>
                  <td>
                    <button mat-icon-button color="primary" matTooltip="Baixar" [matTooltipPosition]="'above'"
                            (click)="downloadFile(item)" aria-label="Baixar o documento">
                      <mat-icon>download</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- 4. Patrimonio Imobiliario Declarante -->
      <div *ngIf="formulario.formularioSteps.checkedDeclarantePatrimonio === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.patrimonios.titleDeclarante"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div class="row border-bottom border-black">
            <div class="col-md-1">
              <h5>Nº</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.patrimonios.columns.description"|translate}}
              </h5>
            </div>
            <div class="col-md-8">
              <h5>
                {{"formDetail.patrimonios.columns.data"|translate}}
              </h5>
            </div>
          </div>

          <div *ngFor="let patrimonioImobiliario of this.formulario.patrimonioImobiliarioList; let i = index;"
               class="row border-bottom border-black pt-3">
            <div class="col-md-1">
              <h5>{{i + 1}}.</h5>
            </div>
            <div class="col-md-3">
              <h5>{{patrimonioImobiliario.descricaoImovel}}</h5>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.patrimonios.local"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{patrimonioImobiliario.localizacaoImovel |titlecase}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.patrimonios.registNumber"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{patrimonioImobiliario.numeroRegisto}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.patrimonios.percentage"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{patrimonioImobiliario.percentagemParticipacao}}%</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.patrimonios.acquisitionDate"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{patrimonioImobiliario.dataAquisicao|date: 'd MMMM y'}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.patrimonios.amount"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{patrimonioImobiliario.valorPago|currency}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.patrimonios.funds"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{patrimonioImobiliario.origemFundos}}</h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- 4.1 Patrimonio Imobiliario Agregado Familiar -->
      <div *ngIf="formulario.formularioSteps.checkedAgregadoPatrimonio  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.patrimonios.titleAgregadoFamiliar"|translate}}
          </h4>
        </div>
        <div class="card-body">

          <div *ngFor="let agregadoFamiliar of agregadoFamiliarList" class="row pt-3">
            <div *ngIf="agregadoFamiliar.patrimonioImobiliarioList.length > 0" class="col-12">
              <div class="row  border-bottom border-black">
                <div class="col-12 text-center text-">
                  <h4>{{agregadoFamiliar.nomeCompleto|titlecase}}</h4>
                </div>
              </div>
              <div class="row border-bottom border-black">
                <div class="col-md-1">
                  <h5>Nº</h5>
                </div>
                <div class="col-md-3">
                  <h5>
                    {{"formDetail.patrimonios.columns.description"|translate}}
                  </h5>
                </div>
                <div class="col-md-8">
                  <h5>
                    {{"formDetail.patrimonios.columns.data"|translate}}
                  </h5>
                </div>
              </div>
              <div *ngFor="let patrimonioImobiliario of agregadoFamiliar.patrimonioImobiliarioList; let i = index;"
                   class="row border-bottom border-black pt-3">
                <div class="col-md-1">
                  <h5>{{i + 1}}.</h5>
                </div>
                <div class="col-md-3">
                  <h5>{{patrimonioImobiliario.descricaoImovel}}</h5>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.patrimonios.local"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{patrimonioImobiliario.localizacaoImovel |titlecase}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.patrimonios.registNumber"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{patrimonioImobiliario.numeroRegisto}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.patrimonios.percentage"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{patrimonioImobiliario.percentagemParticipacao}}%</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.patrimonios.acquisitionDate"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{patrimonioImobiliario.dataAquisicao|date: 'd MMMM y'}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.patrimonios.amount"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{patrimonioImobiliario.valorPago|currency}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.patrimonios.funds"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{patrimonioImobiliario.origemFundos}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="patrimonioDocList.length > 0" class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            ANEXOS DO PATRIMÓNIO IMOBILIÁRIO
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <mat-progress-bar *ngIf="isDownloading" mode="indeterminate"></mat-progress-bar>
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th scope="col">Arquivo</th>
                  <th scope="col">Tamanho</th>
                  <th scope="col">Ação</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of patrimonioDocList">
                  <td>
                    {{ item.fileName }}
                  </td>
                  <td>{{ item.size / 1024 / 1024 | number: '.2' }} MB</td>
                  <td>
                    <button mat-icon-button color="primary" matTooltip="Baixar" [matTooltipPosition]="'above'"
                            (click)="downloadFile(item)" aria-label="Baixar o documento">
                      <mat-icon>download</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- 5. VEÍCULOS, BARCOS E AERONAVES DO DECLARANTE -->
      <div *ngIf="formulario.formularioSteps.checkedDeclaranteVeiculo  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.veiculos.titleDeclarante"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div class="row border-bottom border-black">
            <div class="col-md-1">
              <h5>Nº</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.veiculos.columns.type"|translate}}
              </h5>
            </div>
            <div class="col-md-8">
              <h5>
                {{"formDetail.veiculos.columns.data"|translate}}
              </h5>
            </div>
          </div>

          <div *ngFor="let veiculos of this.formulario.veiculoList; let i = index;"
               class="row border-bottom border-black pt-3">
            <div class="col-md-1">
              <h5>{{i + 1}}.</h5>
            </div>
            <div class="col-md-3">
              <h5>{{veiculos.tipo}}</h5>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.veiculos.brand"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{veiculos.marca}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.veiculos.model"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{veiculos.modelo}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.veiculos.yearManufacture"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{veiculos.anoModelo}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.veiculos.registDate"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{veiculos.dataRegisto|date: 'd MMMM y'}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.veiculos.acquisitionDate"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{veiculos.dataAquisicao|date: 'd MMMM y'}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.veiculos.amount"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{veiculos.valorPago|currency}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.veiculos.funds"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{veiculos.origemFundos}}</h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- 5.1 VEÍCULOS, BARCOS E AERONAVES DO AGREGADO FAMILIAR -->
      <div *ngIf="formulario.formularioSteps.checkedAgregadoVeiculo  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.veiculos.titleAgregadoFamiliar"|translate}}
          </h4>
        </div>
        <div class="card-body">

          <div *ngFor="let agregadoFamiliar of agregadoFamiliarList" class="row pt-3">
            <div *ngIf="agregadoFamiliar.veiculoList.length > 0" class="col-12">
              <div class="row border-bottom border-black">
                <div class="col-12 text-center text-">
                  <h4>{{agregadoFamiliar.nomeCompleto|titlecase}}</h4>
                </div>
              </div>
              <div class="row border-bottom border-black">
                <div class="col-md-1">
                  <h5>Nº</h5>
                </div>
                <div class="col-md-3">
                  <h5>
                    {{"formDetail.veiculos.columns.type"|translate}}
                  </h5>
                </div>
                <div class="col-md-8">
                  <h5>
                    {{"formDetail.veiculos.columns.data"|translate}}
                  </h5>
                </div>
              </div>
              <div *ngFor="let veiculos of agregadoFamiliar.veiculoList; let i = index;"
                   class="row border-bottom border-black pt-3">
                <div class="col-md-1">
                  <h5>{{i + 1}}.</h5>
                </div>
                <div class="col-md-3">
                  <h5>{{veiculos.tipo}}</h5>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.veiculos.brand"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{veiculos.marca}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.veiculos.model"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{veiculos.modelo}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.veiculos.yearManufacture"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{veiculos.anoModelo}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.veiculos.registDate"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{veiculos.dataRegisto|date: 'd MMMM y'}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.veiculos.acquisitionDate"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{veiculos.dataAquisicao|date: 'd MMMM y'}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.veiculos.amount"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{veiculos.valorPago|currency}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.veiculos.funds"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{veiculos.origemFundos}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  Anexos    -->
      <div *ngIf="veiculoDocList.length > 0" class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            ANEXOS DOS VEÍCULOS, BARCOS E AERONAVES
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <mat-progress-bar *ngIf="isDownloading" mode="indeterminate"></mat-progress-bar>
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th scope="col">Arquivo</th>
                  <th scope="col">Tamanho</th>
                  <th scope="col">Ação</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of veiculoDocList">
                  <td>
                    {{ item.fileName }}
                  </td>
                  <td>{{ item.size / 1024 / 1024 | number: '.2' }} MB</td>
                  <td>
                    <button mat-icon-button color="primary" matTooltip="Baixar" [matTooltipPosition]="'above'"
                            (click)="downloadFile(item)" aria-label="Baixar o documento">
                      <mat-icon>download</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- 6. OBJETOS PRECIOSOS COM VALOR SUPERIOR A USD 1500 DO DECLARANTE -->
      <div *ngIf="formulario.formularioSteps.checkedDeclaranteObjeto  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.objetos.titleDeclarante"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div class="row border-bottom border-black">
            <div class="col-md-1">
              <h5>Nº</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.objetos.columns.description"|translate}}
              </h5>
            </div>
            <div class="col-md-8">
              <h5>
                {{"formDetail.objetos.columns.data"|translate}}
              </h5>
            </div>
          </div>

          <div *ngFor="let objeto of this.formulario.objetoPreciososList; let i = index;"
               class="row border-bottom border-black pt-3">
            <div class="col-md-1">
              <h5>{{i + 1}}.</h5>
            </div>
            <div class="col-md-3">
              <h5>{{objeto.descricaoObjeto}}</h5>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.objetos.formAcquisition"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{objeto.formaAquisicao}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.objetos.acquisitionDate"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{objeto.dataAquisicao|date: 'd MMMM y'}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.objetos.amount"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{objeto.valorPago |currency}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.objetos.funds"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{objeto.origemFundos}}</h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- 6.1 OBJETOS PRECIOSOS COM VALOR SUPERIOR A USD 1500 DO AGREGADO FAMILIAR -->
      <div *ngIf="formulario.formularioSteps.checkedAgregadoObjeto  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.objetos.titleAgregadoFamiliar"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div *ngFor="let agregadoFamiliar of agregadoFamiliarList" class="row pt-3">
            <div *ngIf="agregadoFamiliar.objetoPreciososList.length > 0" class="col-12">
              <div class="row border-bottom border-black">
                <div class="col-12 text-center text-">
                  <h4>{{agregadoFamiliar.nomeCompleto|titlecase}}</h4>
                </div>
              </div>
              <div class="row border-bottom border-black">
                <div class="col-md-1">
                  <h5>Nº</h5>
                </div>
                <div class="col-md-3">
                  <h5>
                    {{"formDetail.objetos.columns.description"|translate}}
                  </h5>
                </div>
                <div class="col-md-8">
                  <h5>
                    {{"formDetail.objetos.columns.data"|translate}}
                  </h5>
                </div>
              </div>

              <div *ngFor="let objeto of agregadoFamiliar.objetoPreciososList; let i = index;"
                   class="row border-bottom border-black pt-3">
                <div class="col-md-1">
                  <h5>{{i + 1}}.</h5>
                </div>
                <div class="col-md-3">
                  <h5>{{objeto.descricaoObjeto}}</h5>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.objetos.formAcquisition"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{objeto.formaAquisicao}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.objetos.acquisitionDate"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{objeto.dataAquisicao|date: 'd MMMM y'}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.objetos.amount"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{objeto.valorPago |currency}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.objetos.funds"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{objeto.origemFundos}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--   Anexos   -->
      <div *ngIf="objetoDocList.length > 0" class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            ANEXOS DOS OBJETOS PRECIOSOS
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <mat-progress-bar *ngIf="isDownloading" mode="indeterminate"></mat-progress-bar>
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th scope="col">Arquivo</th>
                  <th scope="col">Tamanho</th>
                  <th scope="col">Ação</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of objetoDocList">
                  <td>
                    {{ item.fileName }}
                  </td>
                  <td>{{ item.size / 1024 / 1024 | number: '.2' }} MB</td>
                  <td>
                    <button mat-icon-button color="primary" matTooltip="Baixar" [matTooltipPosition]="'above'"
                            (click)="downloadFile(item)" aria-label="Baixar o documento">
                      <mat-icon>download</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- 7. INVESTIMENTOS EM AÇÕES, TÍTULOS DE DÍVIDA PÚBLICA E NOUTROS INSTRUMENTOS FINANCEIROS – EM NOME PRÓPRIO -->
      <div *ngIf="formulario.formularioSteps.checkedDeclaranteInvestimentoProprio  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.investimentos.proprio.titleDeclarante"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div class="row border-bottom border-black">
            <div class="col-md-1">
              <h5>Nº</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.investimentos.columns.type"|translate}}
              </h5>
            </div>
            <div class="col-md-8">
              <h5>
                {{"formDetail.investimentos.columns.identification"|translate}}
              </h5>
            </div>
          </div>

          <div *ngFor="let investimento of this.formulario.investimentoProprioList; let i = index;"
               class="row border-bottom border-black pt-3">
            <div class="col-md-1">
              <h5>{{i + 1}}.</h5>
            </div>
            <div class="col-md-3">
              <h5>{{investimento.tipoInstrumento}}</h5>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.investimentos.actualAmount"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{investimento.valorAtual}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.investimentos.registNumber"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{investimento.numeroRegisto}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.investimentos.emissor"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder"> {{investimento.emissor}} </h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.investimentos.quantity"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder"> {{investimento.quantidade}} </h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.investimentos.emissionDate"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{investimento.dataEmissao|date: 'd MMMM y'}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.investimentos.acquisitionDate"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{investimento.dataAquisicao|date: 'd MMMM y'}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.investimentos.amount"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{investimento.valorPago|currency}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.investimentos.funds"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{investimento.origemFundos}}</h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- 7.1 INVESTIMENTOS EM AÇÕES, TÍTULOS DE DÍVIDA PÚBLICA E NOUTROS INSTRUMENTOS FINANCEIROS - AGREGADO FAMILIAR -->
      <div *ngIf="formulario.formularioSteps.checkedAgregadoInvestimentoProprio  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.investimentos.proprio.titleAgregadoFamiliar"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div *ngFor="let agregadoFamiliar of agregadoFamiliarList" class="row pt-3">
            <div *ngIf="agregadoFamiliar.investimentoProprioList.length > 0" class="col-12">
              <div class="row border-bottom border-black">
                <div class="col-12 text-center text-">
                  <h4>{{agregadoFamiliar.nomeCompleto|titlecase}}</h4>
                </div>
              </div>
              <div class="row border-bottom border-black">
                <div class="col-md-1">
                  <h5>Nº</h5>
                </div>
                <div class="col-md-3">
                  <h5>
                    {{"formDetail.investimentos.columns.type"|translate}}
                  </h5>
                </div>
                <div class="col-md-8">
                  <h5>
                    {{"formDetail.investimentos.columns.identification"|translate}}
                  </h5>
                </div>
              </div>

              <div *ngFor="let investimento of agregadoFamiliar.investimentoProprioList; let i = index;"
                   class="row border-bottom border-black pt-3">
                <div class="col-md-1">
                  <h5>{{i + 1}}.</h5>
                </div>
                <div class="col-md-3">
                  <h5>{{investimento.tipoInstrumento}}</h5>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.investimentos.actualAmount"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{investimento.valorAtual}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.investimentos.registNumber"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{investimento.numeroRegisto}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.investimentos.emissor"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder"> {{investimento.emissor}} </h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.investimentos.quantity"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder"> {{investimento.quantidade}} </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.investimentos.emissionDate"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{investimento.dataEmissao|date: 'd MMMM y'}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.investimentos.acquisitionDate"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{investimento.dataAquisicao|date: 'd MMMM y'}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.investimentos.amount"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{investimento.valorPago|currency}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.investimentos.funds"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{investimento.origemFundos}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--   Anexos   -->
      <div *ngIf="investimentoDocList.length > 0" class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            ANEXOS DOS INVESTIMENTOS EM AÇÕES, TÍTULOS DE DÍVIDA PÚBLICA E NOUTROS INSTRUMENTOS FINANCEIROS - EM
            NOME PRÓPRIO
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <mat-progress-bar *ngIf="isDownloading" mode="indeterminate"></mat-progress-bar>
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th scope="col">Arquivo</th>
                  <th scope="col">Tamanho</th>
                  <th scope="col">Ação</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of investimentoDocList">
                  <td>
                    {{ item.fileName }}
                  </td>
                  <td>{{ item.size / 1024 / 1024 | number: '.2' }} MB</td>
                  <td>
                    <button mat-icon-button color="primary" matTooltip="Baixar" [matTooltipPosition]="'above'"
                            (click)="downloadFile(item)" aria-label="Baixar o documento">
                      <mat-icon>download</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- 8. INVESTIMENTOS EM AÇÕES, TÍTULOS DE DÍVIDA PÚBLICA E NOUTROS INSTRUMENTOS FINANCEIROS POR INTERPOSTA PESSOA -->
      <div *ngIf="formulario.formularioSteps.checkedDeclaranteInvestimentoInterposta  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.investimentos.interposta.titleDeclarante"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div class="row border-bottom border-black">
            <div class="col-md-1">
              <h5>Nº</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.investimentos.columns.type"|translate}}
              </h5>
            </div>
            <div class="col-md-8">
              <h5>
                {{"formDetail.investimentos.columns.identification"|translate}}
              </h5>
            </div>
          </div>

          <div *ngFor="let investimento of this.formulario.investimentoInterpostaList; let i = index;"
               class="row border-bottom border-black pt-3">
            <div class="col-md-1">
              <h5>{{i + 1}}.</h5>
            </div>
            <div class="col-md-3">
              <h5>{{investimento.tipoInstrumento}}</h5>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.investimentos.actualAmount"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{investimento.valorAtual |currency}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.investimentos.registNumber"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{investimento.numeroRegisto}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.investimentos.emissor"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder"> {{investimento.emissor}} </h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.investimentos.quantity"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder"> {{investimento.quantidade}} </h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.investimentos.emissionDate"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{investimento.dataEmissao|date: 'd MMMM y'}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.investimentos.acquisitionDate"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{investimento.dataAquisicao|date: 'd MMMM y'}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.investimentos.amount"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{investimento.valorPago|currency}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.investimentos.funds"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{investimento.origemFundos}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.investimentos.income"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{investimento.rendasAoDeclarante|currency}}</h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- 8.1 INVESTIMENTOS EM AÇÕES, TÍTULOS DE DÍVIDA PÚBLICA E NOUTROS INSTRUMENTOS FINANCEIROS - POR INTERPOSTA PESSOA - AGREGADO FAMILIAR -->
      <div *ngIf="formulario.formularioSteps.checkedAgregadoInvestimentoInterposta  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.investimentos.interposta.titleAgregadoFamiliar"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div *ngFor="let agregadoFamiliar of agregadoFamiliarList" class="row pt-3">
            <div *ngIf="agregadoFamiliar.investimentoInterpostaList.length > 0" class="col-12">
              <div class="row border-bottom border-black">
                <div class="col-12 text-center text-">
                  <h4>{{agregadoFamiliar.nomeCompleto|titlecase}}</h4>
                </div>
              </div>
              <div class="row border-bottom border-black">
                <div class="col-md-1">
                  <h5>Nº</h5>
                </div>
                <div class="col-md-3">
                  <h5>
                    {{"formDetail.investimentos.columns.type"|translate}}
                  </h5>
                </div>
                <div class="col-md-8">
                  <h5>
                    {{"formDetail.investimentos.columns.identification"|translate}}
                  </h5>
                </div>
              </div>

              <div *ngFor="let investimento of agregadoFamiliar.investimentoProprioList; let i = index;"
                   class="row border-bottom border-black pt-3">
                <div class="col-md-1">
                  <h5>{{i + 1}}.</h5>
                </div>
                <div class="col-md-3">
                  <h5>{{investimento.tipoInstrumento}}</h5>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.investimentos.actualAmount"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{investimento.valorAtual}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.investimentos.registNumber"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{investimento.numeroRegisto}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.investimentos.emissor"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder"> {{investimento.emissor}} </h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.investimentos.quantity"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder"> {{investimento.quantidade}} </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.investimentos.emissionDate"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{investimento.dataEmissao|date: 'd MMMM y'}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.investimentos.acquisitionDate"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{investimento.dataAquisicao|date: 'd MMMM y'}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.investimentos.amount"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{investimento.valorPago|currency}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.investimentos.funds"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{investimento.origemFundos}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--   Anexos   -->
      <div *ngIf="investimentoInterpostaDocList.length > 0" class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            ANEXOS DOS INVESTIMENTOS EM AÇÕES, TÍTULOS DE DÍVIDA PÚBLICA E NOUTROS INSTRUMENTOS FINANCEIROS POR
            INTERPOSTA PESSOA
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <mat-progress-bar *ngIf="isDownloading" mode="indeterminate"></mat-progress-bar>
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th scope="col">Arquivo</th>
                  <th scope="col">Tamanho</th>
                  <th scope="col">Ação</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of investimentoInterpostaDocList">
                  <td>
                    {{ item.fileName }}
                  </td>
                  <td>{{ item.size / 1024 / 1024 | number: '.2' }} MB</td>
                  <td>
                    <button mat-icon-button color="primary" matTooltip="Baixar" [matTooltipPosition]="'above'"
                            (click)="downloadFile(item)" aria-label="Baixar o documento">
                      <mat-icon>download</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- 9. PARTICIPAÇÕES EM SOCIEDADES COMERCIAIS – EM NOME PRÓPRIO -->
      <div *ngIf="formulario.formularioSteps.checkedDeclaranteParticipacoesProprio  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.participacoesComerciais.proprio.titleDeclarante"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div class="row border-bottom border-black">
            <div class="col-md-1">
              <h5>Nº</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.participacoesComerciais.columns.society"|translate}}
              </h5>
            </div>
            <div class="col-md-8">
              <h5>
                {{"formDetail.participacoesComerciais.columns.identification"|translate}}
              </h5>
            </div>
          </div>

          <div *ngFor="let participacoes of this.formulario.participacoesComerciaisProprioList; let i = index;"
               class="row border-bottom border-black pt-3">
            <div class="col-md-1">
              <h5>{{i + 1}}.</h5>
            </div>
            <div class="col-md-3">
              <h5>{{participacoes.nomeSociedade}}</h5>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.participacoesComerciais.socialObject"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{participacoes.objetoSocial}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.participacoesComerciais.idNumber"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{participacoes.numeroIdentificacaoComercial}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.participacoesComerciais.percentage"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{participacoes.percentagemParticipacao}}%</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.participacoesComerciais.acquisitionDate"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{participacoes.dataAquisicao|date: 'd MMMM y'}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.participacoesComerciais.natureParticipation"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{participacoes.naturezaParticipacao}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.participacoesComerciais.amount"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{participacoes.valorPago|currency}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.participacoesComerciais.funds"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{participacoes.origemFundos}}</h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- 9.1 PARTICIPAÇÕES EM SOCIEDADES COMERCIAIS - AGREGADO FAMILIAR -->
      <div *ngIf="formulario.formularioSteps.checkedAgregadoParticipacoesProprio  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.participacoesComerciais.proprio.titleAgregadoFamiliar"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div *ngFor="let agregadoFamiliar of agregadoFamiliarList" class="row pt-3">
            <div *ngIf="agregadoFamiliar.participacoesComerciaisProprioList.length > 0" class="col-12">
              <div class="row border-bottom border-black">
                <div class="col-12 text-center text-">
                  <h4>{{agregadoFamiliar.nomeCompleto|titlecase}}</h4>
                </div>
              </div>
              <div class="row border-bottom border-black">
                <div class="col-md-1">
                  <h5>Nº</h5>
                </div>
                <div class="col-md-3">
                  <h5>
                    {{"formDetail.participacoesComerciais.columns.society"|translate}}
                  </h5>
                </div>
                <div class="col-md-8">
                  <h5>
                    {{"formDetail.participacoesComerciais.columns.identification"|translate}}
                  </h5>
                </div>
              </div>

              <div *ngFor="let participacoes of agregadoFamiliar.participacoesComerciaisProprioList; let i = index;"
                   class="row border-bottom border-black pt-3">
                <div class="col-md-1">
                  <h5>{{i + 1}}.</h5>
                </div>
                <div class="col-md-3">
                  <h5>{{participacoes.nomeSociedade}}</h5>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.participacoesComerciais.socialObject"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{participacoes.objetoSocial}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.participacoesComerciais.idNumber"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{participacoes.numeroIdentificacaoComercial}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.participacoesComerciais.percentage"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{participacoes.percentagemParticipacao}}%</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.participacoesComerciais.acquisitionDate"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{participacoes.dataAquisicao|date: 'd MMMM y'}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.participacoesComerciais.natureParticipation"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{participacoes.naturezaParticipacao}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.participacoesComerciais.amount"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{participacoes.valorPago|currency}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.participacoesComerciais.funds"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{participacoes.origemFundos}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--   Anexos   -->
      <div *ngIf="participacoesDocList.length > 0" class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            ANEXOS DAS PARTICIPAÇÕES EM SOCIEDADES COMERCIAIS – EM NOME PRÓPRIO
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <mat-progress-bar *ngIf="isDownloading" mode="indeterminate"></mat-progress-bar>
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th scope="col">Arquivo</th>
                  <th scope="col">Tamanho</th>
                  <th scope="col">Ação</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of participacoesDocList">
                  <td>
                    {{ item.fileName }}
                  </td>
                  <td>{{ item.size / 1024 / 1024 | number: '.2' }} MB</td>
                  <td>
                    <button mat-icon-button color="primary" matTooltip="Baixar" [matTooltipPosition]="'above'"
                            (click)="downloadFile(item)" aria-label="Baixar o documento">
                      <mat-icon>download</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- 10. PARTICIPAÇÕES EM SOCIEDADES COMERCIAIS – POR INTERPOSTA PESSOA -->
      <div *ngIf="formulario.formularioSteps.checkedDeclaranteParticipacoesInterposta  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.participacoesComerciais.interposta.titleDeclarante"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div class="row border-bottom border-black">
            <div class="col-md-1">
              <h5>Nº</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.participacoesComerciais.columns.society"|translate}}
              </h5>
            </div>
            <div class="col-md-8">
              <h5>
                {{"formDetail.participacoesComerciais.columns.identification"|translate}}
              </h5>
            </div>
          </div>

          <div *ngFor="let participacoes of this.formulario.participacoesComerciaisInterpostaList; let i = index;"
               class="row border-bottom border-black pt-3">
            <div class="col-md-1">
              <h5>{{i + 1}}.</h5>
            </div>
            <div class="col-md-3">
              <h5>{{participacoes.nomeSociedade}}</h5>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.participacoesComerciais.socialObject"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{participacoes.objetoSocial}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.participacoesComerciais.id"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{participacoes.identificacaoPessoa}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.participacoesComerciais.percentage"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{participacoes.percentagemParticipacao}}%</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.participacoesComerciais.idNumber"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{participacoes.numeroIdentificacaoComercial}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.participacoesComerciais.natureParticipation"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{participacoes.naturezaParticipacao}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.participacoesComerciais.amount"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{participacoes.valorPago|currency}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.participacoesComerciais.funds"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{participacoes.origemFundos}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.participacoesComerciais.acquisitionDate"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{participacoes.dataAquisicao|date: 'd MMMM y'}}</h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- 10.1 PARTICIPAÇÕES EM SOCIEDADES COMERCIAIS - POR INTERPOSTA PESSOA - AGREGADO FAMILIAR -->
      <div *ngIf="formulario.formularioSteps.checkedAgregadoParticipacoesInterposta  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.participacoesComerciais.interposta.titleAgregadoFamiliar"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div *ngFor="let agregadoFamiliar of agregadoFamiliarList" class="row pt-3">
            <div *ngIf="agregadoFamiliar.participacoesComerciaisInterpostaList.length > 0" class="col-12">
              <div class="row border-bottom border-black">
                <div class="col-12 text-center text-">
                  <h4>{{agregadoFamiliar.nomeCompleto|titlecase}}</h4>
                </div>
              </div>
              <div class="row border-bottom border-black">
                <div class="col-md-1">
                  <h5>Nº</h5>
                </div>
                <div class="col-md-3">
                  <h5>
                    {{"formDetail.participacoesComerciais.columns.society"|translate}}
                  </h5>
                </div>
                <div class="col-md-8">
                  <h5>
                    {{"formDetail.participacoesComerciais.columns.identification"|translate}}
                  </h5>
                </div>
              </div>

              <div *ngFor="let participacoes of agregadoFamiliar.participacoesComerciaisInterpostaList; let i = index;"
                   class="row border-bottom border-black pt-3">
                <div class="col-md-1">
                  <h5>{{i + 1}}.</h5>
                </div>
                <div class="col-md-3">
                  <h5>{{participacoes.nomeSociedade}}</h5>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.participacoesComerciais.socialObject"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{participacoes.objetoSocial}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.participacoesComerciais.id"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{participacoes.identificacaoPessoa}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.participacoesComerciais.percentage"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{participacoes.percentagemParticipacao}}%</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.participacoesComerciais.idNumber"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{participacoes.numeroIdentificacaoComercial}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.participacoesComerciais.natureParticipation"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{participacoes.naturezaParticipacao}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.participacoesComerciais.amount"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{participacoes.valorPago|currency}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.participacoesComerciais.funds"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{participacoes.origemFundos}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.participacoesComerciais.acquisitionDate"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{participacoes.dataAquisicao|date: 'd MMMM y'}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--   Anexos   -->
      <div *ngIf="participacoesInterpostaDocList.length > 0" class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            ANEXOS DAS PARTICIPAÇÕES EM SOCIEDADES COMERCIAIS – POR INTERPOSTA PESSOA
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <mat-progress-bar *ngIf="isDownloading" mode="indeterminate"></mat-progress-bar>
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th scope="col">Arquivo</th>
                  <th scope="col">Tamanho</th>
                  <th scope="col">Ação</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of participacoesInterpostaDocList">
                  <td>
                    {{ item.fileName }}
                  </td>
                  <td>{{ item.size / 1024 / 1024 | number: '.2' }} MB</td>
                  <td>
                    <button mat-icon-button color="primary" matTooltip="Baixar" [matTooltipPosition]="'above'"
                            (click)="downloadFile(item)" aria-label="Baixar o documento">
                      <mat-icon>download</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- 11. SALDOS BANCÁRIOS DO DECLARANTE -->
      <div *ngIf="formulario.formularioSteps.checkedDeclaranteSaldoBancario === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.bank.titleDeclarante"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div *ngFor="let saldoBancario of formulario.saldoBancarioList; let i = index;"
               class="row border-bottom border-black pt-3">
            <div class="col-12">
              <div class="row">
                <div class="col-md-1">
                  <h5>{{i + 1}}.</h5>
                </div>
                <div class="col-md-4">
                  <h5>
                    {{"formDetail.bank.accountNature"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{saldoBancario.naturezaConta}}</h5>
                </div>
                <div class="col-md-4">
                  <h5>
                    {{"formDetail.bank.ownership"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{saldoBancario.titularidade}}</h5>
                </div>
              </div>

              <div *ngIf="saldoBancario.pessoaConjuntaList.length > 0" class="row my-2">
                <div class="col-12">
                  <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-11">
                      <h5>
                        {{"formDetail.bank.jointPerson.title"|translate}}:
                      </h5>
                    </div>
                  </div>
                  <div *ngFor="let pessoaConjunta of saldoBancario.pessoaConjuntaList; let i = index;" class="row pt-2">
                    <div class="col-md-1"></div>
                    <div class="col-md-4">
                      <h5>
                        {{"formDetail.bank.jointPerson.name"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{pessoaConjunta.nome}}</h5>
                    </div>
                    <div class="col-md-4">
                      <h5>
                        {{"formDetail.bank.jointPerson.percentage"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{pessoaConjunta.percentagem}}%</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-4">
                  <h5>
                    {{"formDetail.bank.bank"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{saldoBancario.nomeBanco}}</h5>
                </div>
                <div class="col-md-4">
                  <h5>
                    {{"formDetail.bank.accountNumber"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{saldoBancario.numeroConta}}</h5>
                </div>
              </div>

              <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-4">
                  <h5>
                    {{"formDetail.bank.currency"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{saldoBancario.tipoMoeda}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.bank.saldo"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{saldoBancario.saldoPresente|currency}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 11.1 SALDOS BANCÁRIOS DO AGREGADO FAMILIAR -->
      <div *ngIf="formulario.formularioSteps.checkedAgregadoSaldoBancario  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.bank.titleAgregadoFamiliar"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div *ngFor="let agregadoFamiliar of agregadoFamiliarList" class="row pt-3">
            <div *ngIf="agregadoFamiliar.saldoBancarioList.length > 0" class="col-12">
              <div class="row border-bottom border-black">
                <div class="col-12 text-center text-">
                  <h4>{{agregadoFamiliar.nomeCompleto|titlecase}}</h4>
                </div>
              </div>
              <div *ngFor="let saldoBancario of agregadoFamiliar.saldoBancarioList; let i = index;"
                   class="row border-bottom border-black pt-3">
                <div class="col-12">
                  <div class="row">
                    <div class="col-md-1">
                      <h5>{{i + 1}}.</h5>
                    </div>
                    <div class="col-md-4">
                      <h5>
                        {{"formDetail.bank.accountNature"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{saldoBancario.naturezaConta}}</h5>
                    </div>
                    <div class="col-md-4">
                      <h5>
                        {{"formDetail.bank.ownership"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{saldoBancario.titularidade}}</h5>
                    </div>
                  </div>

                  <div *ngIf="saldoBancario.pessoaConjuntaList.length > 0" class="row my-2">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-11">
                          <h5>
                            {{"formDetail.bank.jointPerson.title"|translate}}:
                          </h5>
                        </div>
                      </div>
                      <div *ngFor="let pessoaConjunta of saldoBancario.pessoaConjuntaList; let i = index;"
                           class="row pt-2">
                        <div class="col-md-1"></div>
                        <div class="col-md-4">
                          <h5>
                            {{"formDetail.bank.jointPerson.name"|translate}}:
                          </h5>
                          <h5 class="font-weight-bolder">{{pessoaConjunta.nome}}</h5>
                        </div>
                        <div class="col-md-4">
                          <h5>
                            {{"formDetail.bank.jointPerson.percentage"|translate}}:
                          </h5>
                          <h5 class="font-weight-bolder">{{pessoaConjunta.percentagem}}%</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-4">
                      <h5>
                        {{"formDetail.bank.bank"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{saldoBancario.nomeBanco}}</h5>
                    </div>
                    <div class="col-md-4">
                      <h5>
                        {{"formDetail.bank.accountNumber"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{saldoBancario.numeroConta}}</h5>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-4">
                      <h5>
                        {{"formDetail.bank.currency"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{saldoBancario.tipoMoeda}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.bank.saldo"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{saldoBancario.saldoPresente |currency}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--   Anexos   -->
      <div *ngIf="saldoDocList.length > 0" class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            ANEXOS DOS SALDOS BANCÁRIOS
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <mat-progress-bar *ngIf="isDownloading" mode="indeterminate"></mat-progress-bar>
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th scope="col">Arquivo</th>
                  <th scope="col">Tamanho</th>
                  <th scope="col">Ação</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of saldoDocList">
                  <td>
                    {{ item.fileName }}
                  </td>
                  <td>{{ item.size / 1024 / 1024 | number: '.2' }} MB</td>
                  <td>
                    <button mat-icon-button color="primary" matTooltip="Baixar" [matTooltipPosition]="'above'"
                            (click)="downloadFile(item)" aria-label="Baixar o documento">
                      <mat-icon>download</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- 12. DÍVIDAS E OUTRAS OBRIGAÇÕES FINANCEIRAS -->
      <div *ngIf="formulario.formularioSteps.checkedDeclaranteDividas  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.debts.titleDeclarante"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div class="row border-bottom border-black">
            <div class="col-md-1">
              <h5>Nº</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.debts.columns.category"|translate}}
              </h5>
            </div>
            <div class="col-md-8">
              <h5>
                {{"formDetail.debts.columns.identification"|translate}}
              </h5>
            </div>
          </div>

          <div *ngFor="let dividas of this.formulario.dividasList; let i = index;"
               class="row border-bottom border-black pt-3">
            <div class="col-md-1">
              <h5>{{i + 1}}.</h5>
            </div>
            <div class="col-md-3">
              <h5>{{dividas.categoria}}</h5>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.debts.creditorId"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{dividas.identificacaoCredor}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.debts.debtAmount"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{dividas.montanteDivida|currency}}</h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- 12.1 DÍVIDAS E OUTRAS OBRIGAÇÕES FINANCEIRAS DO AGREGADO FAMILIAR -->
      <div *ngIf="formulario.formularioSteps.checkedAgregadoDividas  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.debts.titleAgregadoFamiliar"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div *ngFor="let agregadoFamiliar of agregadoFamiliarList" class="row pt-3">
            <div *ngIf="agregadoFamiliar.dividasList.length > 0" class="col-12">
              <div class="row border-bottom border-black">
                <div class="col-12 text-center text-">
                  <h4>{{agregadoFamiliar.nomeCompleto|titlecase}}</h4>
                </div>
              </div>
              <div class="row border-bottom border-black">
                <div class="col-md-1">
                  <h5>Nº</h5>
                </div>
                <div class="col-md-3">
                  <h5>
                    {{"formDetail.debts.columns.category"|translate}}
                  </h5>
                </div>
                <div class="col-md-8">
                  <h5>
                    {{"formDetail.debts.columns.identification"|translate}}
                  </h5>
                </div>
              </div>

              <div *ngFor="let dividas of agregadoFamiliar.dividasList; let i = index;"
                   class="row border-bottom border-black pt-3">
                <div class="col-md-1">
                  <h5>{{i + 1}}.</h5>
                </div>
                <div class="col-md-3">
                  <h5>{{dividas.categoria}}</h5>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.debts.creditorId"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{dividas.identificacaoCredor}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.debts.debtAmount"|translate}} 
                      </h5>
                      <h5 class="font-weight-bolder">{{dividas.montanteDivida|currency}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!--   Anexos   -->
      <div *ngIf="dividasDocList.length > 0" class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            ANEXOS DAS DÍVIDAS E OUTRAS OBRIGAÇÕES FINANCEIRAS
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <mat-progress-bar *ngIf="isDownloading" mode="indeterminate"></mat-progress-bar>
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th scope="col">Arquivo</th>
                  <th scope="col">Tamanho</th>
                  <th scope="col">Ação</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of dividasDocList">
                  <td>
                    {{ item.fileName }}
                  </td>
                  <td>{{ item.size / 1024 / 1024 | number: '.2' }} MB</td>
                  <td>
                    <button mat-icon-button color="primary" matTooltip="Baixar" [matTooltipPosition]="'above'"
                            (click)="downloadFile(item)" aria-label="Baixar o documento">
                      <mat-icon>download</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- 13. PRENDAS RECEBIDAS DE VALOR SUPERIOR A USD 250 -->
      <div *ngIf="formulario.formularioSteps.checkedDeclarantePrendasRecebidas  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.prendasRecebidas.titleDeclarante"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div class="row border-bottom border-black">
            <div class="col-md-1">
              <h5>Nº</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.prendasRecebidas.columns.description"|translate}}
              </h5>
            </div>
            <div class="col-md-8">
              <h5>
                {{"formDetail.prendasRecebidas.columns.identification"|translate}}
              </h5>
            </div>
          </div>

          <div *ngFor="let prendas of this.formulario.prendasRecebidasList; let i = index;"
               class="row border-bottom border-black pt-3">
            <div class="col-md-1">
              <h5>{{i + 1}}.</h5>
            </div>
            <div class="col-md-3">
              <h5>{{prendas.descricao}}</h5>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.prendasRecebidas.approximateValue"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{prendas.valorAproximado|currency}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.prendasRecebidas.donorId"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{prendas.identificacaoDoador}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.prendasRecebidas.relation"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{prendas.relacaoDoador}}</h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- 13.1 PRENDAS RECEBIDAS DE VALOR SUPERIOR A USD 250 DO AGREGADO FAMILIAR -->
      <div *ngIf="formulario.formularioSteps.checkedAgregadoPrendasRecebidas  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.prendasRecebidas.titleAgregadoFamiliar"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div *ngFor="let agregadoFamiliar of agregadoFamiliarList" class="row pt-3">
            <div *ngIf="agregadoFamiliar.prendasRecebidasList.length > 0" class="col-12">
              <div class="row border-bottom border-black">
                <div class="col-12 text-center text-">
                  <h4>{{agregadoFamiliar.nomeCompleto|titlecase}}</h4>
                </div>
              </div>
              <div class="row border-bottom border-black">
                <div class="col-md-1">
                  <h5>Nº</h5>
                </div>
                <div class="col-md-3">
                  <h5>
                    {{"formDetail.prendasRecebidas.columns.description"|translate}}
                  </h5>
                </div>
                <div class="col-md-8">
                  <h5>
                    {{"formDetail.prendasRecebidas.columns.identification"|translate}}
                  </h5>
                </div>
              </div>

              <div *ngFor="let prendas of agregadoFamiliar.prendasRecebidasList; let i = index;"
                   class="row border-bottom border-black pt-3">
                <div class="col-md-1">
                  <h5>{{i + 1}}.</h5>
                </div>
                <div class="col-md-3">
                  <h5>{{prendas.descricao}}</h5>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.prendasRecebidas.approximateValue"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{prendas.valorAproximado|currency}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.prendasRecebidas.donorId"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{prendas.identificacaoDoador}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.prendasRecebidas.relation"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{prendas.relacaoDoador}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!--   Anexos   -->
      <div *ngIf="prendasDocList.length > 0" class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            ANEXOS DAS PRENDAS RECEBIDAS DE VALOR SUPERIOR A USD 250
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <mat-progress-bar *ngIf="isDownloading" mode="indeterminate"></mat-progress-bar>
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th scope="col">Arquivo</th>
                  <th scope="col">Tamanho</th>
                  <th scope="col">Ação</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of prendasDocList">
                  <td>
                    {{ item.fileName }}
                  </td>
                  <td>{{ item.size / 1024 / 1024 | number: '.2' }} MB</td>
                  <td>
                    <button mat-icon-button color="primary" matTooltip="Baixar" [matTooltipPosition]="'above'"
                            (click)="downloadFile(item)" aria-label="Baixar o documento">
                      <mat-icon>download</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- 14. VIAGENS E OUTRAS DESPESAS LUXUOSAS -->
      <div *ngIf="formulario.formularioSteps.checkedDeclaranteViagensDespesas  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.viagens.titleDeclarante"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div class="row border-bottom border-black">
            <div class="col-md-1">
              <h5>Nº</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.viagens.columns.description"|translate}}
              </h5>
            </div>
            <div class="col-md-8">
              <h5>
                {{"formDetail.viagens.columns.identification"|translate}}
              </h5>
            </div>
          </div>

          <div *ngFor="let viagens of this.formulario.viagensDespesasList; let i = index;"
               class="row border-bottom border-black pt-3">
            <div class="col-md-1">
              <h5>{{i + 1}}.</h5>
            </div>
            <div class="col-md-3">
              <h5>{{viagens.descricaoViagemDespesas}}</h5>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.viagens.type"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{viagens.tipo}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.viagens.approximateValue"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{viagens.valorAproximado|currency}}</h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- 14.1 VIAGENS E OUTRAS DESPESAS LUXUOSAS DO AGREGADO FAMILIAR -->
      <div *ngIf="formulario.formularioSteps.checkedAgregadoViagensDespesas  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.viagens.titleAgregadoFamiliar"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div *ngFor="let agregadoFamiliar of agregadoFamiliarList" class="row pt-3">
            <div *ngIf="agregadoFamiliar.viagensDespesasList.length > 0" class="col-12">
              <div class="row border-bottom border-black">
                <div class="col-12 text-center text-">
                  <h4>{{agregadoFamiliar.nomeCompleto|titlecase}}</h4>
                </div>
              </div>
              <div class="row border-bottom border-black">
                <div class="col-md-1">
                  <h5>Nº</h5>
                </div>
                <div class="col-md-3">
                  <h5>
                    {{"formDetail.viagens.columns.description"|translate}}
                  </h5>
                </div>
                <div class="col-md-8">
                  <h5>
                    {{"formDetail.viagens.columns.identification"|translate}}
                  </h5>
                </div>
              </div>

              <div *ngFor="let viagens of agregadoFamiliar.viagensDespesasList; let i = index;"
                   class="row border-bottom border-black pt-3">
                <div class="col-md-1">
                  <h5>{{i + 1}}.</h5>
                </div>
                <div class="col-md-3">
                  <h5>{{viagens.descricaoViagemDespesas}}</h5>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.viagens.type"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{viagens.tipo}}</h5>
                    </div>
                    <div class="col-md-6">
                      <h5>
                        {{"formDetail.viagens.approximateValue"|translate}}
                      </h5>
                      <h5 class="font-weight-bolder">{{viagens.valorAproximado|currency}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!--   Anexos   -->
      <div *ngIf="viagensDocList.length > 0" class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            ANEXOS DAS VIAGENS E OUTRAS DESPESAS LUXUOSAS
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <mat-progress-bar *ngIf="isDownloading" mode="indeterminate"></mat-progress-bar>
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th scope="col">Arquivo</th>
                  <th scope="col">Tamanho</th>
                  <th scope="col">Ação</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of viagensDocList">
                  <td>
                    {{ item.fileName }}
                  </td>
                  <td>{{ item.size / 1024 / 1024 | number: '.2' }} MB</td>
                  <td>
                    <button mat-icon-button color="primary" matTooltip="Baixar" [matTooltipPosition]="'above'"
                            (click)="downloadFile(item)" aria-label="Baixar o documento">
                      <mat-icon>download</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- 15. MEMBRO INTEGRANTE DE ÓRGÃO COLEGIAL DE SOCIEDADE COMERCIAL -->
      <div *ngIf="formulario.formularioSteps.checkedDeclaranteMembroIntegrante  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.membroIntegrante.title"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <div class="row border-bottom border-black">
            <div class="col-md-1">
              <h5>Nº</h5>
            </div>
            <div class="col-md-3">
              <h5>
                {{"formDetail.membroIntegrante.columns.organ"|translate}}
              </h5>
            </div>
            <div class="col-md-8">
              <h5>
                {{"formDetail.membroIntegrante.columns.identification"|translate}}
              </h5>
            </div>
          </div>

          <div *ngFor="let membro of this.formulario.membroIntegranteList; let i = index;"
               class="row border-bottom border-black pt-3">
            <div class="col-md-1">
              <h5>{{i + 1}}.</h5>
            </div>
            <div class="col-md-3">
              <h5>{{membro.orgao}}</h5>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.membroIntegrante.position"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{membro.cargo}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.membroIntegrante.societyId"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{membro.sociedadeComercial}}</h5>
                </div>
                <div class="col-md-6">
                  <h5>
                    {{"formDetail.membroIntegrante.amount"|translate}}
                  </h5>
                  <h5 class="font-weight-bolder">{{membro.valorRemunercaoAuferida|currency}}</h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!--   Anexos   -->
      <div *ngIf="membroDocList.length > 0" class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            ANEXOS DO MEMBRO INTEGRANTE DE ÓRGÃO COLEGIAL DE SOCIEDADE COMERCIAL
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <mat-progress-bar *ngIf="isDownloading" mode="indeterminate"></mat-progress-bar>
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th scope="col">Arquivo</th>
                  <th scope="col">Tamanho</th>
                  <th scope="col">Ação</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of membroDocList">
                  <td>
                    {{ item.fileName }}
                  </td>
                  <td>{{ item.size / 1024 / 1024 | number: '.2' }} MB</td>
                  <td>
                    <button mat-icon-button color="primary" matTooltip="Baixar" [matTooltipPosition]="'above'"
                            (click)="downloadFile(item)" aria-label="Baixar o documento">
                      <mat-icon>download</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- 16. PARTICIPAÇÃO EM ORGANIZAÇÃO PRIVADA, PARTIDO POLÍTICO, ASSOCIAÇÃO OU DE OUTRAS ORGANIZAÇÕES SEM FINS LUCRATIVOS -->
      <div *ngIf="formulario.formularioSteps.checkedDeclaranteParticipacaoOrgao  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.participacao.title"|translate}}
          </h4>
        </div>
        <div class="card-body">

          <div *ngFor="let participacao of this.formulario.participacaoOrgaoList; let i = index;"
               class="row border-bottom border-black pt-3">
            <div class="col-md-1">
              <h5>{{i + 1}}.</h5>
            </div>
            <div class="col-md-6">
              <h5>
                {{"formDetail.participacao.type"|translate}}
              </h5>
              <h5 class="font-weight-bolder">{{participacao.tipoOrganizacao | titlecase}}</h5>
              <h5>
                {{"formDetail.participacao.position"|translate}}
              </h5>
              <h5 class="font-weight-bolder">{{participacao.cargo}}</h5>
            </div>
            <div class="col-md-4">
              <h5>
                {{"formDetail.participacao.identification"|translate}}
              </h5>
              <h5 class="font-weight-bolder">{{participacao.identificacaoOrganizacao}}</h5>
              <h5>
                {{"formDetail.participacao.value"|translate}}
              </h5>
              <h5 class="font-weight-bolder">{{participacao.valorRemuneracao | currency}}</h5>
            </div>
          </div>

        </div>
      </div>

      <!--   Anexos   -->
      <div *ngIf="participacaoDocList.length > 0" class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            ANEXOS DA PARTICIPAÇÃO EM ORGANIZAÇÃO PRIVADA, PARTIDO POLÍTICO, ASSOCIAÇÃO OU DE OUTRAS
            ORGANIZAÇÕES SEM FINS LUCRATIVOS
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <mat-progress-bar *ngIf="isDownloading" mode="indeterminate"></mat-progress-bar>
              <table class="table table-borderless">
                <thead>
                <tr>
                  <th scope="col">Arquivo</th>
                  <th scope="col">Tamanho</th>
                  <th scope="col">Ação</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of participacaoDocList">
                  <td>
                    {{ item.fileName }}
                  </td>
                  <td>{{ item.size / 1024 / 1024 | number: '.2' }} MB</td>
                  <td>
                    <button mat-icon-button color="primary" matTooltip="Baixar" [matTooltipPosition]="'above'"
                            (click)="downloadFile(item)" aria-label="Baixar o documento">
                      <mat-icon>download</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- 17. RECUSA DE PRESTAÇÃO DE INFORMAÇÃO POR PARTE DE MEMBRO DO AGREGADO FAMILIAR -->
      <div *ngIf="formulario.formularioSteps.checkedRecusaPrestacao  === stepChecked"
           class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.recusa.title"|translate}}
          </h4>
        </div>
        <div class="card-body">

          <div *ngFor="let recusa of this.formulario.recusaPrestacaoList let i = index;"
               class="row border-bottom border-black pt-3">
            <div class="col-md-1">
              <h5>{{i + 1}}.</h5>
            </div>
            <div class="col-md-6">
              <h5>
                {{"formDetail.recusa.agregadoFamiliar"|translate}}
              </h5>
              <h5 class="font-weight-bolder">{{recusa.agregadoFamiliar.nomeCompleto}}</h5>
            </div>
            <div class="col-md-5">
              <h5>
                {{"formDetail.recusa.information"|translate}}  
              </h5>
              <h5 class="font-weight-bolder">{{recusa.informacao}}</h5>
            </div>
          </div>

        </div>
      </div>

      <div *ngIf="formulario.informacao" class="card mt-lg-4 mt-md-4 mt-sm-2">
        <div class="card-header">
          <h4 class="card-title">
            {{"formDetail.informations.title"|translate}}
          </h4>
        </div>
        <div class="card-body">
          <h5>
            {{"formDetail.informations.information"|translate}}
          </h5>
          <p>{{formulario.informacao}}</p>
        </div>
      </div>
    </div>
  </div>
</mat-card>
