<mat-card class="animated fadeIn">
  <mat-card-header>
    <mat-card-title>
      Resumo Rendimentos, Bens e Interesses
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="mt-3">
    <ngx-spinner type="ball-clip-rotate-pulse" [fullScreen]="false" color="#ffff"></ngx-spinner>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="index">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-75"> Nº </mat-header-cell>
        <mat-cell class="w-75" *matCellDef="let i = index">
          <span class="mobile-label">Nº :</span>
          {{i+1}}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef class="bold mobile-hide">
          <span class="mobile-value">Total</span>
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="riqueza">
        <mat-header-cell *matHeaderCellDef> Riqueza </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{item.riqueza}} </mat-cell>
        <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="total">
        <mat-header-cell *matHeaderCellDef> Total </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{item.total | currency}} </mat-cell>

        <mat-footer-cell *matFooterCellDef> {{total| currency}} </mat-footer-cell>
      </ng-container>

      <!-- SubTotal -->
      <ng-container matColumnDef="subLabel">
        <mat-footer-cell *matFooterCellDef>
          Sub-total
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="blank">
        <mat-footer-cell *matFooterCellDef>
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="subTotal">
        <mat-footer-cell  *matFooterCellDef>
          {{subTotal| currency}}
        </mat-footer-cell>
      </ng-container>

      <!-- Dividas -->
      <ng-container matColumnDef="blank1">
        <mat-footer-cell id="numberDividas" class="w-75" *matFooterCellDef>
          <span class="mobile-label">Nº :</span>
          11
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="dividasLabel">
        <mat-footer-cell class="font-weight-normal text-center justify-content-center" *matFooterCellDef>
          {{dividas?.riqueza}}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="dividas">
        <mat-footer-cell class="font-weight-normal text-center justify-content-center" *matFooterCellDef>
          {{dividas?.total| currency}}
        </mat-footer-cell>
      </ng-container>


      <!-- Viagens -->
      <ng-container matColumnDef="blank2">
        <mat-footer-cell id="numberViagens" class="w-75" *matFooterCellDef>
          <span class="mobile-label">Nº :</span>
          12
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="viagensLabel">
        <mat-footer-cell class="font-weight-normal text-center justify-content-center" *matFooterCellDef>
          {{viagens?.riqueza}}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="viagens">
        <mat-footer-cell class="font-weight-normal text-center justify-content-center" *matFooterCellDef>
          {{viagens?.total| currency}}
        </mat-footer-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

      <mat-footer-row *matFooterRowDef="['subLabel','blank', 'subTotal']" class="example-second-footer-row">
      </mat-footer-row>

      <mat-footer-row *matFooterRowDef="['blank1', 'dividasLabel', 'dividas']" class="table-row"></mat-footer-row>

      <mat-footer-row *matFooterRowDef="['blank2', 'viagensLabel', 'viagens']" class="table-row"></mat-footer-row>

      <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
    </table>
  </mat-card-content>
</mat-card>
