import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private readonly dashboardUrl = `${environment.url}/dashboard`;

  constructor(
    private http: HttpClient
  ) { }

  getDeclarantesAndFormulariosTotal(anoDeclaracao?: string): Observable<any> {
    if (anoDeclaracao) {
      const params = new HttpParams()
        .set('anoDeclaracao', anoDeclaracao);
      return this.http.get<any>(`${this.dashboardUrl}/formularios`, { params }).pipe(
        take(1),
        catchError(this.handleError)
      );
    } else {
      return this.http.get<any>(`${this.dashboardUrl}/formularios`).pipe(
        take(1),
        catchError(this.handleError)
      );
    }
  }

  getTotalFormularioPeriodo(anoDeclaracao?: string): Observable<any> {
    if (anoDeclaracao) {
      const params = new HttpParams()
        .set('anoDeclaracao', anoDeclaracao);
      return this.http.get<any>(`${this.dashboardUrl}/formularios/periodo`, { params }).pipe(
        take(1),
        catchError(this.handleError)
      );
    } else {
      return this.http.get<any>(`${this.dashboardUrl}/formularios/periodo`).pipe(
        take(1),
        catchError(this.handleError)
      );
    }
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error.error);
    }
  }

}
