<div class="container-fluid content">
  <div class="row">
    <div class="col-lg-7 vh-100 d-flex justify-content-center align-items-center">
      <router-outlet></router-outlet>
    </div>
    <div class="col-md-4 d-md-down-none d-flex align-items-center justify-content-center">
      <img src="assets/images/cac.png" alt="RDTL" width="400" height="400" class="img-fluid">
    </div>
  </div>
  <div class="fixed-bottom text-white text-center pb-2">
    <span>Copyright&nbsp;&copy; 2022 <a class="text-white" href="https://cac.tl/">Comissão Anti-Corrupção</a>
    All Rights Reserved. &nbsp;</span>
    <span>Developed & Hosted by <a class="text-white" href="https://www.tic.gov.tl">TIC TIMOR I.P.</a></span>
  </div>
</div>

