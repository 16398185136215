import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PrendasRecebidas } from '../models/formulario/secoes/prendas-recebidas';

@Injectable({
  providedIn: 'root'
})
export class PrendasRecebidasService {
  private readonly prendasRecebidasUrl = `${environment.url}/prendas-recebidas`;
  private readonly formUrl = `${environment.url}/formulario`;

constructor(
  private http: HttpClient,
) { }

saveAll(formularioId: any, form: PrendasRecebidas[]): Observable<PrendasRecebidas[]> {
  return this.http.post<PrendasRecebidas[]>(`${this.formUrl}/${formularioId}/prendas-recebidas-list`, form).pipe(
    take(1),
    catchError(this.handleError)
  );
}

updateAll(formularioId: any, form: PrendasRecebidas[]): Observable<PrendasRecebidas[]> {
  return this.http.put<PrendasRecebidas[]>(`${this.formUrl}/${formularioId}/prendas-recebidas-list`, form).pipe(
    take(1),
    catchError(this.handleError)
  );
}

deleteById(id: any): Observable<number> {
  return this.http.delete<number>(`${this.prendasRecebidasUrl}/${id}`).pipe(
    take(1),
    catchError(this.handleError)
  );
}

private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error.error);
    }
  }
}
