import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RecusaPrestacao } from '../models/formulario/secoes/recusa-prestacao';

@Injectable({
  providedIn: 'root'
})
export class RecusaPrestacaoService {
  private readonly recusaPrestacaoUrl = `${environment.url}/recusa-prestacao`;
  private readonly formUrl = `${environment.url}/formulario`;

  constructor(
    private http: HttpClient,
  ) { }

  saveAll(formularioId: any, form: RecusaPrestacao[]): Observable<RecusaPrestacao[]> {
    return this.http.post<RecusaPrestacao[]>(`${this.formUrl}/${formularioId}/recusa-prestacao-list`, form).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  updateAll(formularioId: any, form: RecusaPrestacao[]): Observable<RecusaPrestacao[]> {
    return this.http.put<RecusaPrestacao[]>(`${this.formUrl}/${formularioId}/recusa-prestacao-list`, form).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  deleteById(id: any): Observable<number> {
    return this.http.delete<number>(`${this.recusaPrestacaoUrl}/${id}`).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error.error);
    }
  }

}
