import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Declarante } from '../models/declarante';
import { PagedResponse } from '../models/formulario/PagedResponse';
import { Forms } from '../models/formulario/forms';
import { Instituicao } from '../models/instituicao';

@Injectable({
  providedIn: 'root'
})
export class InstituicaoService {
  private readonly url = `${environment.url}/instituicao`;

  constructor(private http: HttpClient) {
  }

  save(form: any): Observable<Instituicao> {
    return this.http.post<Instituicao>(`${this.url}/`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updateById(id: any, form: any): Observable<Instituicao> {
    return this.http.put<Instituicao>(`${this.url}/${id}`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  getById(id: any): Observable<Instituicao> {
    return this.http.get<Instituicao>(`${this.url}/${id}`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  getDetailById(id: any): Observable<Instituicao> {
    return this.http.get<Instituicao>(`${this.url}/detail/${id}`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  getByQuery(query: string): Observable<Instituicao[]> {
    const params = new HttpParams()
      .set('query', query);
    return this.http.get<Instituicao[]>(`${this.url}/search`, { params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getAll(): Observable<Instituicao[]> {
    return this.http.get<Instituicao[]>(`${this.url}/`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  getPage(page: number, size: number, anoRegisto?: number): Observable<PagedResponse<Instituicao>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());

    if (anoRegisto) {
      params = params.set('anoRegisto', anoRegisto.toString());
    }
    return this.http.get<PagedResponse<Instituicao>>(`${this.url}/page`, { params })
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  getDeclaranteList(id: number, page: number, size: number): Observable<PagedResponse<Declarante>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());
    return this.http.get<PagedResponse<Declarante>>(`${this.url}/${id}/declarante`, { params })
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  getFormularioList(id: number, page: number, size: number, anoDeclaracao?: string): Observable<PagedResponse<Forms>> {
    if (anoDeclaracao) {
      const params = new HttpParams()
        .set('anoDeclaracao', anoDeclaracao)
        .set('page', page.toString())
        .set('size', size.toString());
      return this.http.get<PagedResponse<Forms>>(`${this.url}/${id}/formulario`, { params })
        .pipe(
          take(1),
          catchError(this.handleError)
        );
    }
    else {
      const params = new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString());
      return this.http.get<PagedResponse<Forms>>(`${this.url}/${id}/formulario`, { params })
        .pipe(
          take(1),
          catchError(this.handleError)
        );
    }
  }

  deleteById(id: any): Observable<any> {
    return this.http.delete<any>(`${this.url}/${id}`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }


  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error.error);
    }
  }
}
