<app-header class="layout-header shadow-lg" [navbarBrandRouterLink]="['/dashboard']" [fixed]="true"
  [navbarBrandFull]="{src: 'assets/images/cac.png', width: 50, height: 50, alt: 'CAC Logo'}"
  [navbarBrandMinimized]="{src: 'assets/images/cac.png', width: 30, height: 30, alt: 'CAC Logo'}"
  [sidebarToggler]="'lg'" [asideMenuToggler]="false">
  <ul class="nav navbar-nav ml-auto mr-4">
    <li class="nav-item mr-4 d-md-down-none">
      <mat-button-toggle-group (change)="languageOnChange($event)" #group="matButtonToggleGroup">
        <mat-button-toggle class="text-white" value="tp" aria-label="Text align left">
          Tetum
        </mat-button-toggle>
        <mat-button-toggle class="text-white" value="pt" aria-label="Text align center">
          Português
        </mat-button-toggle>
      </mat-button-toggle-group>
    </li>
    <li class="nav-item d-md-down-none">
      <mat-icon matPrefix>account_circle</mat-icon>
    </li>
    <li class="nav-item d-md-down-none">
      <span class="font-weight-bolder">
        {{session.nomeCompleto}}
      </span>
    </li>
    <li class="nav-item">
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button [routerLink]="['/admin/perfil']" mat-menu-item>
          <mat-icon>person</mat-icon>
          Perfíl
        </button>
        <button (click)="logOut()" mat-menu-item>
          <mat-icon>logout</mat-icon>
          Sair
        </button>
      </mat-menu>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
    (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="navItems"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <cui-breadcrumb>
      <!-- Breadcrumb Menu-->
      <li class="breadcrumb-menu d-md-down-none">
        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
        </div>
      </li>
    </cui-breadcrumb>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>
  <app-aside [fixed]="true" [display]="false">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <mat-button-toggle-group (change)="languageOnChange($event)" #group="matButtonToggleGroup">
          <mat-button-toggle value="tp" aria-label="Text align left">
            Tetum
          </mat-button-toggle>
          <mat-button-toggle value="pt" aria-label="Text align center">
            Português
          </mat-button-toggle>
        </mat-button-toggle-group>
      </li>
    </ul>
  </app-aside>
</div>
<app-footer class="justify-content-center">
  <span>Copyright&nbsp;&copy; {{year |date:"yyyy"}} <a href="https://cac.tl/">Comissão Anti-Corrupção</a>
    All Rights Reserved. &nbsp;</span>
  <span>Developed & Hosted by <a href="https://www.tic.gov.tl">TIC TIMOR I.P.</a></span>
</app-footer>
