import { INavData } from '@coreui/angular';

export const navItemsSuperAdminPT: INavData[] = [
    {
        name: 'Painel',
        url: '/admin/painel',
        icon: 'pi pi-microsoft',
    },
    {
        name: 'Formulários',
        url: '/admin/formulario/list',
        icon: 'pi pi-file-o',
    },
    {
        name: 'Declarantes',
        url: 'admin/declarante/list',
        icon: 'pi pi-user',
    },
    {
      name: 'Agregado Familiar',
      url: 'admin/agregado-familiar/list',
      icon: 'pi pi-users',
    },
    {
        name: 'Usuários',
        url: 'admin/usuario/list',
        icon: 'pi pi-user',
    },
    {
        name: 'Instituições',
        url: 'admin/instituicao/list',
        icon: 'pi pi-building',
    },
    {
        name: 'Históricos',
        url: 'admin/historico/list',
        icon: 'pi pi-book'
    },
];

export const navItemsSuperAdminTP: INavData[] = [
  {
    name: 'Panél',
    url: '/admin/painel',
    icon: 'pi pi-microsoft',
  },
  {
    name: 'Formuláriu',
    url: '/admin/formulario/list',
    icon: 'pi pi-file-o',
  },
  {
    name: 'Deklarante',
    url: 'admin/declarante/list',
    icon: 'pi pi-user',
  },
  {
    name: 'Agregadu Familia',
    url: 'admin/agregado-familiar/list',
    icon: 'pi pi-users',
  },
  {
    name: 'Uzuáriu',
    url: 'admin/usuario/list',
    icon: 'pi pi-user',
  },
  {
    name: 'Instituisaun',
    url: 'admin/instituicao/list',
    icon: 'pi pi-building',
  },
  {
    name: 'Historiku',
    url: 'admin/historico/list',
    icon: 'pi pi-book'
  },
];

export const navItemsAdminPT: INavData[] = [
    {
        name: 'Painel',
        url: '/admin/painel',
        icon: 'pi pi-microsoft',
    },
    {
        name: 'Formulários',
        url: '/admin/formulario/list',
        icon: 'pi pi-file-o',
    },
    {
        name: 'Declarantes',
        url: 'admin/declarante/list',
        icon: 'pi pi-users',
    },
    {
      name: 'Agregado Familiar',
      url: 'admin/agregado-familiar/list',
      icon: 'pi pi-users',
    },
    {
      name: 'Instituições',
      url: 'admin/instituicao/list',
      icon: 'pi pi-building',
    },
];

export const navItemsAdminTP: INavData[] = [
  {
    name: 'Panél',
    url: '/admin/painel',
    icon: 'pi pi-microsoft',
  },
  {
    name: 'Formuláriu',
    url: '/admin/formulario/list',
    icon: 'pi pi-file-o',
  },
  {
    name: 'Deklarante',
    url: 'admin/declarante/list',
    icon: 'pi pi-users',
  },
  {
    name: 'Agregadu Familia',
    url: 'admin/agregado-familiar/list',
    icon: 'pi pi-users',
  },
  {
    name: 'Instituisaun',
    url: 'admin/instituicao/list',
    icon: 'pi pi-building',
  },
];
