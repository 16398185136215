import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Declarante } from 'src/app/core/models/declarante';
import { AuthenticationService } from 'src/app/core/services';
import { navItems_Pt, navItems_Tp } from '../../navigations/declarante_nav';
@Component({
  selector: 'app-dashboard',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class DeclaranteLayoutComponent {
  year: Date;
  public sidebarMinimized = false;
  public navItems = navItems_Pt;
  session: Declarante;
  selectedCountry: any;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    public translate: TranslateService,
  ) {
    this.year = new Date();
    this.year.getFullYear();
    this.session = this.authService.currentUserValue;
  }

  toggleMinimize(e: any): void {
    this.sidebarMinimized = e;
  }

  logOut(): void {
    this.authService.logout();
    this.router.navigate(['/declarante/login']);
  }

  languageOnChange(event: any) {
    switch (event.value) {
      case 'tp':
        this.navItems = navItems_Tp;
        this.translate.use(event.value);
        break;
      case 'pt':
        this.navItems = navItems_Pt;
        this.translate.use(event.value);
        break;
    }
  }

}
