import { INavData } from '@coreui/angular';

export const navItems_Pt: INavData[] = [
    {
        name: 'Início',
        url: '/declarante/inicio',
        icon: 'pi pi-microsoft',
    },
    {
        name: 'Agregado Familiar',
        url: '/declarante/agregado-familiar',
        icon: 'pi pi-users',
    },
    {
        name: 'Formulários',
        url: 'declarante/formulario/',
        icon: 'pi pi-file-o',
        children: [
            {
                name: 'Novo',
                url: '/declarante/formulario/novo',
                icon: 'pi pi-plus',
            },
            {
                name: 'Esboços',
                url: '/declarante/formulario/draft',
                icon: 'pi pi-envelope',
            },
            {
              name: 'Submetidos',
              url: '/declarante/formulario/list',
              icon: 'pi pi-send',
            },
        ]
    },
];

export const navItems_Tp: INavData[] = [
    {
        name: 'Inísiu',
        url: '/declarante/inicio',
        icon: 'pi pi-microsoft',
    },
    {
        name: 'Agregadu Familiar',
        url: '/declarante/agregado-familiar',
        icon: 'pi pi-users',
    },
    {
        name: 'Formuláriu',
        url: 'declarante/formulario/',
        icon: 'pi pi-file-o',
        children: [
            {
                name: 'Foun',
                url: '/declarante/formulario/novo',
                icon: 'pi pi-plus',
            },
            {
                name: 'Esbosu',
                url: '/declarante/formulario/draft',
                icon: 'pi pi-envelope',
            },
            {
              name: 'Submetidu',
              url: '/declarante/formulario/list',
              icon: 'pi pi-send',
            },
        ]
    },
];
