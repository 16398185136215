import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'active'
})
export class ActivePipe implements PipeTransform {

  transform(value: boolean, args?: any): string {
    if (value) {
      return 'Ativado';
    } else {
      return 'Desativado';
    }
  }

}
