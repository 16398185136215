import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SaldoBancario } from '../models/formulario/secoes/bancario/saldo-bancario';

@Injectable({
  providedIn: 'root'
})
export class SaldoBancarioService {
  private readonly saldoBancarioUrl = `${environment.url}/saldo-bancario`;
  private readonly formUrl = `${environment.url}/formulario`;

  constructor(
    private http: HttpClient,
  ) { }

  saveAll(formularioId: any, form: SaldoBancario[]): Observable<SaldoBancario[]> {
    return this.http.post<SaldoBancario[]>(`${this.formUrl}/${formularioId}/saldo-bancario-list`, form).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  updateAll(formularioId: any, form: SaldoBancario[]): Observable<SaldoBancario[]> {
    return this.http.put<SaldoBancario[]>(`${this.formUrl}/${formularioId}/saldo-bancario-list`, form).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  deleteById(id: any): Observable<number> {
    return this.http.delete<number>(`${this.saldoBancarioUrl}/${id}`).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  deletePessoaConjuntaById(id: number, pessoaId: number): Observable<number> {
    return this.http.delete<number>(`${this.saldoBancarioUrl}/${id}/pessoa-conjunta/${pessoaId}`).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error.error);
    }
  }

}
