import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  constructor(
    private http: HttpClient,
  ) {
  }

  get currentUserValue(): any {
    const user = localStorage.getItem('user');
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  get getToken(): any {
    return localStorage.getItem('id_token');
  }


  private setSession(authResult: any): any {
    localStorage.clear();
    localStorage.setItem('user', JSON.stringify(authResult));
    localStorage.setItem('id_token', authResult.token);
  }


  loginDeclarante(form: any): any {
    return this.http.post<any>(`${environment.url}/declarants/authenticate`, form, this.httpOptions)
      .pipe(map(async declarante => {
          this.setSession(declarante);
          return declarante;
        }),
        catchError(this.handleError)
      );
  }

  loginUser(form: any): Observable<any> {
    return this.http.post<any>(`${environment.url}/users/authenticate`, form)
      .pipe(map(async user => {
          this.setSession(user);
          return user;
        }),
        catchError(this.handleError)
      );
  }

  logout(): void {
    localStorage.clear();
  }


  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error.error);
    }
  }
}
