import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, take} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {Forms} from '../models/formulario/forms';
import {PagedResponse} from '../models/formulario/PagedResponse';

@Injectable({
  providedIn: 'root'
})
export class FormularioService {
  private readonly url = `${environment.url}/formulario`;

  constructor(
    private http: HttpClient,
  ) {
  }

  save(form: any): Observable<Forms> {
    return this.http.post<Forms>(`${this.url}/`, form).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  updateById(id: any, form: any): Observable<Forms> {
    return this.http.put<Forms>(`${this.url}/${id}`, form).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  deleteById(id: any): Observable<number> {
    return this.http.delete<number>(`${this.url}/${id}`).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  getByid(id: any): Observable<Forms> {
    return this.http.get<Forms>(`${this.url}/${id}`).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  getByNumeroFormulario(numeruFormulario: string): Observable<Forms> {
    return this.http.get<Forms>(`${this.url}/${numeruFormulario}/comprovante`).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  getAll(page: number, size: number, anoDeclaracao?: string): Observable<PagedResponse<Forms>> {
    if (anoDeclaracao) {
      const params = new HttpParams()
        .set('anoDeclaracao', anoDeclaracao)
        .set('page', page.toString())
        .set('size', size.toString());
      return this.http.get<PagedResponse<Forms>>(`${this.url}/`, { params }).pipe(
        take(1),
        catchError(this.handleError)
      );
    } else {
      const params = new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString());
      return this.http.get<PagedResponse<Forms>>(`${this.url}/`, { params }).pipe(
        take(1),
        catchError(this.handleError)
      );
    }
  }

  getByQuery(query: string): Observable<Forms[]> {
    const params = new HttpParams()
      .set('query', query);
    return this.http.get<Forms[]>(`${this.url}/search/`, {params})
      .pipe(
        catchError(this.handleError)
      );
  }

  validateTokenAndNumeroFormulario(token: string, numeroFormulario: string): Observable<any> {
    const params = new HttpParams()
      .set('token', token)
      .set('numeroFormulario', numeroFormulario);
    return this.http.get<any>(`${this.url}/comprovante`, {params})
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error.error);
    }
  }

}
