<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<div class="container-fluid">
  <div class="row mb-lg-3 mb-md-3 mb-sm-2">
    <h4>
      {{"profile.tab.information.dialog.title"|translate}}
    </h4>
  </div>
  <form [formGroup]="authForm" class="mt-3 mt-sm-3 mt-md-4 mt-lg-4">
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>
          {{"profile.tab.information.dialog.form.password.label"|translate}}
        </mat-label>
        <input id="input" formControlName="senha" matInput [type]="hide ? 'password' : 'text'" required>
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </form>
  <div class="row justify-content-end">
    <button mat-button class="mr-lg-2 mr-md-2" [mat-dialog-close]="true">
      <mat-icon>cancel</mat-icon>
      {{"profile.tab.information.dialog.buttons.cancel"|translate}}
    </button>
    <button mat-button color="primary" (click)="closeDialog(authForm)" [disabled]="!authForm.valid">
      <mat-icon>lock_open</mat-icon>
      {{"profile.tab.information.dialog.buttons.authenticate"|translate}}
    </button>
  </div>
</div>
