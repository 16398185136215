import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {INavData} from '@coreui/angular';
import {
  navItemsAdminPT,
  navItemsAdminTP,
  navItemsSuperAdminPT,
  navItemsSuperAdminTP
} from 'src/app/containers/navigations/admin_nav';
import {Role} from 'src/app/core/enums/role.enum';
import {AuthenticationService} from 'src/app/core/services';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css']
})
export class AdminLayoutComponent implements OnInit {
  year: Date;
  public sidebarMinimized = false;
  public navItems!: INavData[];
  session: any;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    public translate: TranslateService,
  ) {
    this.year = new Date();
    this.year.getFullYear();
    this.session = this.authService.currentUserValue;
    this.checkedRole(this.session.role);
  }

  ngOnInit(): void {
  }

  checkedRole(role: Role): void {
    role === Role.super_admin ?
      this.navItems = navItemsSuperAdminPT :
      this.navItems = navItemsAdminPT ;
  }

  toggleMinimize(e: any): void {
    this.sidebarMinimized = e;
  }

  logOut(): void {
    this.authService.logout();
    this.router.navigate(['/admin/login']);
  }

  languageOnChange(event: any): void {
    if (this.session.role === Role.super_admin) {
      switch (event.value) {
        case 'tp':
          this.navItems = navItemsSuperAdminTP;
          this.translate.use(event.value);
          break;
        case 'pt':
          this.navItems = navItemsSuperAdminPT;
          this.translate.use(event.value);
          break;
      }
    } else {
      switch (event.value) {
        case 'tp':
          this.navItems = navItemsAdminTP;
          this.translate.use(event.value);
          break;
        case 'pt':
          this.navItems = navItemsAdminPT;
          this.translate.use(event.value);
          break;
      }
    }
  }


}
