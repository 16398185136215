import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {
  private readonly url = `${environment.url}/utils`;

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Validate reCaptcha response
   * @param response
   * @returns
   */
  validateResponse(response: string): Observable<any> {
    const params = new HttpParams().set('response', response);
    return this.http.post<any>(`${this.url}/recaptcha/validate`, {}, { params })
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error.error);
    }
  }
}
