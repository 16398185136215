import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Declarante } from '../models/declarante';
import { PagedResponse } from '../models/formulario/PagedResponse';
import { Forms } from '../models/formulario/forms';
import { Usuario } from '../models/usuario';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  private readonly url = `${environment.url}/users`;

  constructor(private http: HttpClient) {
  }

  save(form: any): Observable<Usuario> {
    return this.http.post<Usuario>(`${this.url}/`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updateById(id: any, form: any): Observable<Usuario> {
    return this.http.put<Usuario>(`${this.url}/${id}`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  passwordAuthentication(id: any, form: any): Observable<Usuario> {
    return this.http.post<Usuario>(`${this.url}/authenticate/${id}`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  getById(id: any): Observable<Usuario> {
    return this.http.get<Usuario>(`${this.url}/${id}`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  getAll(page: number, size: number): Observable<PagedResponse<Usuario>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());
    return this.http.get<PagedResponse<Usuario>>(`${this.url}/`, { params })
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  resetPassword(id: any, form: any): Observable<Usuario> {
    return this.http.put<Usuario>(`${this.url}/${id}/reset-credentials/`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updatePassword(id: any, form: any): Observable<Usuario> {
    return this.http.put<Usuario>(`${this.url}/${id}/credentials/`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  getDeclaranteById(usuarioId: any, declaranteId: number): Observable<Declarante> {
    return this.http.get<Declarante>(`${this.url}/${usuarioId}/declarantes/${declaranteId}`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  getAllDeclarante(usuarioId: any, page: number, size: number, anoRegisto?: number): Observable<PagedResponse<Declarante>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());

    if (anoRegisto) {
      params = params.set('anoRegisto', anoRegisto.toString());
    }
    return this.http.get<PagedResponse<Declarante>>(`${this.url}/${usuarioId}/declarantes/`, { params })
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  getDeclaranteByQuery(usuarioId: any, query: string): Observable<Declarante[]> {
    const params = new HttpParams()
      .set('query', query);
    return this.http.get<Declarante[]>(`${this.url}/${usuarioId}/declarantes/search/`, { params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getDeclaranteByQueryAndInstituicaoId(usuarioId: any, instituicaoId: any, query: string): Observable<Declarante[]> {
    const params = new HttpParams()
      .set('query', query);
    return this.http.get<Declarante[]>(`${this.url}/${usuarioId}/declarantes/instituicao/${instituicaoId}/search/`, { params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getNaoDeclaradoList(id: number, instituicaoId: number, inicio: any, prazo: any): Observable<Declarante[]> {
    const params = new HttpParams()
      .set('inicio', inicio)
      .set('prazo', prazo);
    return this.http.get<Declarante[]>(`${this.url}/${id}/instituicao/${instituicaoId}/nao-declarou/`, { params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getFormularioByQueryAndInstituicaoId(usuarioId: any, instituicaoId: any, query: string): Observable<Forms[]> {
    const params = new HttpParams()
      .set('query', query);
    return this.http.get<Forms[]>(`${this.url}/${usuarioId}/formularios/instituicao/${instituicaoId}/search/`, { params })
      .pipe(
        catchError(this.handleError)
      );
  }

  saveDeclarante(usuarioId: any, form: any): Observable<Declarante> {
    return this.http.post<Declarante>(`${this.url}/${usuarioId}/declarantes/`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  updateDeclarante(usuarioId: any, declaranteId: any, form: any): Observable<Declarante> {
    return this.http.put<Declarante>(`${this.url}/${usuarioId}/declarantes/${declaranteId}`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  resetDeclaranteCredentials(usuarioId: any, declaranteId: any, form: any): Observable<Declarante> {
    return this.http.put<Declarante>(`${this.url}/${usuarioId}/declarantes/${declaranteId}/credentials/`, form)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  sendEmailConfirmation(declarante: Declarante): Observable<any> {
    return this.http.post<Declarante>(`${this.url}/email/declarantes`, declarante)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error.error);
    }
  }

}
