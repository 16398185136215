import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthenticationService} from '../services';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  private adminLoginUrl = 'admin';

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken;

    if (token !== null) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.error instanceof ErrorEvent) {
            return throwError(error);
          } else {
            if (error.status === 401 || error.status === 403) {
              this.spinner.hide();
              this.authService.logout();
              if (this.router.url.includes(this.adminLoginUrl)) {
                this.router.navigate(['/admin']).then(() => this.spinner.hide());
              } else {
                this.router.navigate(['/']).then(() => this.spinner.hide());
              }
            }
          }
          return throwError(error);
        })
      );
  }
}
