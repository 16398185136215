import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {TipoOrganizacao} from 'src/app/core/enums/tipoOrganizacap.enum';
import {Forms} from 'src/app/core/models/formulario/forms';
import {Resumo} from 'src/app/core/models/formulario/resumo';
import {SaldoBancario} from 'src/app/core/models/formulario/secoes/bancario/saldo-bancario';
import {Dividas} from 'src/app/core/models/formulario/secoes/dividas';
import {InvestimentoInterposta} from 'src/app/core/models/formulario/secoes/investimento/investimento-interposta';
import {InvestimentoProprio} from 'src/app/core/models/formulario/secoes/investimento/investimento-proprio';
import {ObjetoPreciosos} from 'src/app/core/models/formulario/secoes/objeto-preciosos';
import {ParticipacaoOrgao} from 'src/app/core/models/formulario/secoes/participacao-orgao';
import {ParticipacoesComerciais} from 'src/app/core/models/formulario/secoes/participacoes/participacoes-comerciais';
import {PatrimonioImobiliario} from 'src/app/core/models/formulario/secoes/patrimonio-imobiliario';
import {PrendasRecebidas} from 'src/app/core/models/formulario/secoes/prendas-recebidas';
import {Rendimentos} from 'src/app/core/models/formulario/secoes/rendimentos/rendimentos';
import {Veiculo} from 'src/app/core/models/formulario/secoes/veiculo';
import {ViagensDespesas} from 'src/app/core/models/formulario/secoes/viagens-despesas';
import {FormularioService} from 'src/app/core/services';
import {MatSnackBar} from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-resumo',
  templateUrl: './resumo.component.html',
  styleUrls: ['./resumo.component.css']
})
export class ResumoComponent implements OnInit, OnDestroy, AfterViewInit {
  private querySubscription: Subscription | undefined;
  displayedColumns: string[];
  displayedColumnsSubTotal: string[];
  displayedColumnsTotal: string[];
  dataSource: any;
  dividas!: Resumo;
  viagens!: Resumo;
  subTotal = 0;
  total = 0;
  formularioId!: number;
  listaResumo: Resumo[] = [];
  totalParticipacaoOrgPublico!: number;
  totalParticipacaoOrgPrivado!: number;

  constructor(
    private route: ActivatedRoute,
    private formularioService: FormularioService,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
  ) {
    this.displayedColumns = ['index', 'riqueza', 'total'];
    this.displayedColumnsSubTotal = ['vatAmountTitle', 'emptyFooter', 'emptyFooter', 'vatAmount'];
    this.displayedColumnsTotal = ['totalAmountTitle', 'emptyFooter', 'emptyFooter', 'totalAmount'];
  }

  ngOnInit(): void {
    this.formularioId = this.route.snapshot.params.id;
    if (this.formularioId) {
      this.spinner.show();
      this.formularioService.getByid(
        this.formularioId
      ).subscribe({
        next: (formulario) => {
          this.totalParticipacaoOrganizacao(formulario.participacaoOrgaoList);
          this.calcularTotal(formulario);
          this.spinner.hide();
        },
        error: (error) => this.errorAlert(error.message)
      });
    }
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    this.querySubscription?.unsubscribe();
  }

  calcularTotal(form: Forms): number {
    const rendimento: Resumo = {
      riqueza: 'Rendimento',
      total: form.rendimentoList.length === 1 ? this.totalRendimento(form.rendimentoList[0]) : 0,
    };

    const imoveis: Resumo = {
      riqueza: 'Imóveis',
      total: this.totalImoveis(form.patrimonioImobiliarioList)
    };

    const veiculos: Resumo = {
      riqueza: 'Veículos, Barcos e Aeronaves',
      total: this.totalVeiculos(form.veiculoList)
    };

    const objetos: Resumo = {
      riqueza: 'Objetos Preciosos com valor superior a USD 1500',
      total: this.totalObjetosPreciosos(form.objetoPreciososList)
    };

    const investimentos: Resumo = {
      riqueza: 'Investimentos',
      total: this.totalInvestimentos(form.investimentoProprioList, form.investimentoInterpostaList)
    };

    const sociedadesComerciais: Resumo = {
      riqueza: 'Sociedades Comerciais',
      total: this.totalSociedadesComerciais(form.participacoesComerciaisProprioList, form.participacoesComerciaisInterpostaList)
    };

    const contaBancaria: Resumo = {
      riqueza: 'Saldos Bancários',
      total: this.totalContaBancaria(form.saldoBancarioList)
    };

    const participacaoOrganizacaoPublico: Resumo = {
      riqueza: 'Organização Público',
      total: this.totalParticipacaoOrgPublico
    };

    const participacaoOrganizacaoPrivado: Resumo = {
      riqueza: 'Organização Privado',
      total: this.totalParticipacaoOrgPrivado
    };

    this.dividas = {
      riqueza: 'Dívidas e Outras Obrigações Financeiras',
      total: this.totalDividas(form.dividasList),
    };

    const prendas: Resumo = {
      riqueza: 'Prendas Recebidas de Valor superior a USD 250 ',
      total: this.totalPrendas(form.prendasRecebidasList)
    };

    this.viagens = {
      riqueza: 'Vigens e Outras Despesas Luxosas acima de $2500',
      total: this.totalViagem(form.viagensDespesasList)
    };

    this.listaResumo.push(
      rendimento,
      imoveis,
      veiculos,
      objetos,
      investimentos,
      sociedadesComerciais,
      contaBancaria,
      participacaoOrganizacaoPublico,
      participacaoOrganizacaoPrivado,
      prendas,
    );

    this.dataSource = new MatTableDataSource(this.listaResumo);

    this.subTotal =
      rendimento.total +
      imoveis.total +
      veiculos.total +
      objetos.total +
      investimentos.total +
      sociedadesComerciais.total +
      contaBancaria.total +
      participacaoOrganizacaoPublico.total +
      participacaoOrganizacaoPrivado.total +
      prendas.total;
    this.total = this.subTotal - (this.viagens.total + this.dividas.total);

    return this.total;
  }

  totalRendimento(obj: Rendimentos): number {
    return obj.salariosAnual +
      obj.honorariosAnual +
      obj.pensoesAnual +
      obj.rendasAnual +
      obj.lucrosAnual +
      obj.jurosAnual +
      obj.compensacoesAnual +
      obj.receitasVendasAnual +
      obj.emprestimosContraidosAnual +
      obj.ganhosJogosFortunaAnual +
      obj.valoresPatrimoniosAnual;

  }

  totalImoveis(listaPatrimonio: PatrimonioImobiliario[]): number {
    let sumTotal = 0;
    if (listaPatrimonio.length > 0) {
      for (const obj of listaPatrimonio) {
        sumTotal += obj.valorPago;
      }
    }
    return sumTotal;
  }

  totalVeiculos(listaVeiculo: Veiculo[]): number {
    let sumTotal = 0;
    if (listaVeiculo.length > 0) {
      for (const obj of listaVeiculo) {
        sumTotal += obj.valorPago;
      }
    }
    return sumTotal;
  }

  totalObjetosPreciosos(listaObjetos: ObjetoPreciosos[]): number {
    let sumTotal = 0;
    if (listaObjetos.length > 0) {
      for (const obj of listaObjetos) {
        sumTotal += obj.valorPago;
      }
    }
    return sumTotal;
  }

  totalInvestimentos(
    investimentoProprio: InvestimentoProprio[], investimentoInterposta: InvestimentoInterposta[]
  ): number {
    let sumTotalProprio = 0;
    let sumTotalInterposta = 0;

    if (investimentoProprio.length > 0 || investimentoInterposta.length > 0) {
      for (const obj of investimentoProprio) {
        sumTotalProprio += obj.valorPago;
      }

      for (const obj of investimentoInterposta) {
        sumTotalInterposta += obj.valorPago;
      }
    }

    return sumTotalProprio + sumTotalProprio;
  }

  totalSociedadesComerciais(
    sociedadesProprio: ParticipacoesComerciais[],
    sociedadesInterposta: ParticipacoesComerciais[]
  ): number {
    let sumTotalProprio = 0;
    let sumTotalInterposta = 0;

    if (sociedadesProprio.length > 0 || sociedadesInterposta.length > 0) {
      for (const obj of sociedadesProprio) {
        sumTotalProprio += obj.valorPago;
      }

      for (const obj of sociedadesInterposta) {
        sumTotalInterposta += obj.valorPago;
      }
    }

    return sumTotalProprio + sumTotalProprio;
  }

  totalParticipacaoOrganizacao(listaParticipacaoOrganizacao: ParticipacaoOrgao[]): void {
    this.totalParticipacaoOrgPublico = 0;
    this.totalParticipacaoOrgPrivado = 0;
    if (listaParticipacaoOrganizacao.length > 0) {
      for (const obj of listaParticipacaoOrganizacao) {
        obj.tipoOrganizacao === TipoOrganizacao.publico ?
          this.totalParticipacaoOrgPublico += obj.valorRemuneracao :
          this.totalParticipacaoOrgPrivado += obj.valorRemuneracao;
      }
    }
  }

  totalContaBancaria(listaSaldoBancario: SaldoBancario[]): number {
    let sumTotal = 0;
    if (listaSaldoBancario.length > 0) {
      for (const obj of listaSaldoBancario) {
        sumTotal += obj.saldoPresente;
      }
    }
    return sumTotal;
  }

  totalDividas(listaDividas: Dividas[]): number {
    let sumTotal = 0;
    if (listaDividas.length > 0) {
      for (const obj of listaDividas) {
        sumTotal += obj.montanteDivida;
      }
    }
    return sumTotal;
  }

  totalPrendas(listaPrendas: PrendasRecebidas[]): number {
    let sumTotal = 0;
    if (listaPrendas.length > 0) {
      for (const obj of listaPrendas) {
        sumTotal += obj.valorAproximado;
      }
    }
    return sumTotal;
  }

  totalViagem(listaViagens: ViagensDespesas[]): number {
    let sumTotal = 0;
    if (listaViagens.length > 0) {
      for (const obj of listaViagens) {
        sumTotal += obj.valorAproximado;
      }
    }
    return sumTotal;
  }

  errorAlert(description: string): void {
    this.snackBar.open('Erro', description, {
      panelClass: 'danger-dialog',
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 2000,
    });
  }
}
