<mat-card [ngStyle]="setMatCardStyle(user)">
  <div class="d-flex justify-content-between">
    <mat-card-title>
      <h1>
        {{"faq.title"|translate}}
      </h1>
    </mat-card-title>
    <div *ngIf="!user">
      <mat-button-toggle-group (change)="languageOnChange($event)" #group="matButtonToggleGroup">
        <mat-button-toggle class="" value="tp" aria-label="Tetum">
          Tetum
        </mat-button-toggle>
        <mat-button-toggle class="" value="pt" aria-label="Portuguese">
          Português
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <mat-card-content>

    <mat-accordion>

      <!-- 1st panel       -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{"faq.first.q"|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{"faq.first.a.p1"|translate}}
        </p>
        <p>
          {{"faq.first.a.p2"|translate}}
        </p>
      </mat-expansion-panel>

      <!-- 2nd panel       -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{"faq.second.q"|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{"faq.second.a"|translate}}
        </p>
      </mat-expansion-panel>

      <!-- 3rd panel       -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{"faq.third.q"|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{"faq.third.a"|translate}}
        </p>
      </mat-expansion-panel>

      <!-- 4th panel       -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{"faq.fourth.q"|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{"faq.fourth.a"|translate}}
        </p>
      </mat-expansion-panel>

      <!-- 5th panel       -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{"faq.fifth.q"|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{"faq.fifth.a"|translate}}
        </p>
      </mat-expansion-panel>

      <!-- 6th panel       -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{"faq.sixth.q"|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{"faq.sixth.a"|translate}}
        </p>
      </mat-expansion-panel>

      <!-- 7th panel       -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{"faq.seventh.q"|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{"faq.seventh.a"|translate}}
        </p>
      </mat-expansion-panel>

      <!-- 8th panel       -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{"faq.eighth.q"|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{"faq.eighth.a"|translate}}
        </p>
      </mat-expansion-panel>

      <!-- 9th panel       -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{"faq.ninth.q"|translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{"faq.ninth.a"|translate}}
        </p>
      </mat-expansion-panel>

    </mat-accordion>

  </mat-card-content>
  <button (click)="back()" class="button-red text-white font-weight-bold font-lg" mat-raised-button>
    <mat-icon>keyboard_return</mat-icon>
    Voltar
  </button>
</mat-card>
