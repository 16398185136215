import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivePipe } from './active.pipe';
import { UnderscoreFilterPipe } from './underscore-filter.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ActivePipe,
    UnderscoreFilterPipe,
  ],
  exports: [
    ActivePipe,
    UnderscoreFilterPipe,
  ]
})
export class PipesModule { }
