import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Cargo } from '../models/cargo';

@Injectable({
  providedIn: 'root'
})
export class CargoService {
  private readonly url = `${environment.url}/cargo`;

  constructor(private http: HttpClient) { }

  getById(id: any): Observable<Cargo> {
    return this.http.get<Cargo>(`${this.url}/${id}`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  deleteById(id: any): Observable<any> {
    return this.http.delete<any>(`${this.url}/${id}`)
      .pipe(
        take(1),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error.error);
    }
  }
}
