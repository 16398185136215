import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/views/declarante/formulario/formulario.component';

@Component({
  selector: 'app-verification-dialog',
  templateUrl: './verification-dialog.component.html',
  styleUrls: ['./verification-dialog.component.css']
})
export class VerificationDialogComponent implements OnInit {
  hide = true;
  authForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<VerificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    private renderer: Renderer2,
  ) {

    this.authForm = this.formBuilder.group({
      senha: ['', [Validators.minLength(6)]],
    })
  }

  ngOnInit(): void {
    this.renderer.selectRootElement('#input').focus();
    this.dialogRef.updatePosition({
      top: `15em`,
    });
  }

  closeDialog(authForm: FormGroup): void {
    this.dialogRef.close({ event: 'close', data: authForm.value });
  }


}
