import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Rendimentos } from '../models/formulario/secoes/rendimentos/rendimentos';

@Injectable({
  providedIn: 'root'
})
export class RendimentoService {
  private readonly rendimentoUrl = `${environment.url}/rendimentos`;
  private readonly formUrl = `${environment.url}/formulario`;

  constructor(
    private http: HttpClient,
  ) { }

  save(formId: any, form: Rendimentos): Observable<Rendimentos> {
    return this.http.post<Rendimentos>(`${this.formUrl}/${formId}/rendimentos`, form).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  updateById(id: any, form: any): Observable<Rendimentos> {
    return this.http.put<Rendimentos>(`${this.rendimentoUrl}/${id}`, form).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  deleteById(id: any): Observable<any> {
    return this.http.delete<any>(`${this.rendimentoUrl}/${id}`).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  deleteEntidadeEmprestimoById(rendimentoId: any, id: any): Observable<number> {
    return this.http.delete<number>(`${this.rendimentoUrl}/${rendimentoId}/entidade/${id}`).pipe(
      take(1),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(error.error);
    }
  }


}
