<mat-card>
  <mat-card-title class="mb-4 ">
    <h1 class="text-white">
      Login
    </h1>
  </mat-card-title>
  <!-- Login Content -->
  <form [formGroup]="form">
    <mat-card-content>
      <mat-card-subtitle>
        <div *ngIf="showAlert" class="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Erro!&nbsp;</strong>{{alertMessages}}
          <button (click)="showAlert = false" type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </mat-card-subtitle>
      <div class="form-row">
        <mat-form-field appearance="standard">
          <mat-label>Nome do usuário</mat-label>
          <input matInput formControlName="username" required>
          <mat-icon matSuffix>person</mat-icon>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field appearance="standard">
          <mat-label>Senha</mat-label>
          <input matInput formControlName="senha" [type]="hide ? 'password' : 'text'" required>
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </mat-card-content>
    <!-- Register Content -->
    <mat-card-actions class="d-flex justify-content-center">
      <button type="button" (click)="captchaRef.execute();" class="button-red text-white font-weight-bold font-lg w-100"
              mat-raised-button>
        <mat-icon>login</mat-icon>
        ENTRAR
      </button>
    </mat-card-actions>
  </form>
</mat-card>
<re-captcha #captchaRef="reCaptcha" [lang]="'pt'" [badge]="'bottomleft'" size="invisible"
            (resolved)="$event && resolved($event, form)" siteKey="{{siteKey}}"></re-captcha>
<ngx-spinner type="ball-clip-rotate-pulse" [fullScreen]="true" color="#ffff">
</ngx-spinner>
