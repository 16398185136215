import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { NgxSpinnerService } from 'ngx-spinner';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Alignment } from 'pdfmake/interfaces';
import { Role } from 'src/app/core/enums/role.enum';
import { Forms } from 'src/app/core/models/formulario/forms';
import { AuthenticationService, DeclaranteService, FormularioService } from 'src/app/core/services';
import { Estado } from '../../../core/enums/estado.enum';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-comprovante',
  templateUrl: './comprovante.component.html',
  styleUrls: ['./comprovante.component.css']
})
export class ComprovanteComponent implements OnInit {
  formulario!: Forms;
  @ViewChild('comprovante') comprovante!: ElementRef;
  qrcode!: any;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private formularioService: FormularioService,
    private declaranteService: DeclaranteService,
    private spinner: NgxSpinnerService,
    private authService: AuthenticationService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.spinner.show();
    const numeroFormulario = this.route.snapshot.params.numeroFormulario;
    const declaranteId = this.authService.currentUserValue.id;
    const role = this.authService.currentUserValue.role;

    this.determineRole(declaranteId, numeroFormulario, role);
  }

  determineRole(declaranteId: number, numeroFormulario: string, role: Role): void {
    switch (role) {
      case Role.super_admin:
        this.getUsuarioFormularioDetailByNumeroFormulario(numeroFormulario);
        break;
      case Role.admin:
        this.getUsuarioFormularioDetailByNumeroFormulario(numeroFormulario);
        break;
      case Role.declarante:
        this.getDeclaranteFormularioDetailByNumeroFormulario(declaranteId, numeroFormulario);
        break;
    }
  }

  getUsuarioFormularioDetailByNumeroFormulario(numeroFormulario: string): void {
    this.formularioService.getByNumeroFormulario(numeroFormulario).subscribe({
      next: (response) => {
        this.formulario = response;
        this.qrcode = this.sanitizer.bypassSecurityTrustUrl(`data:image/jpg;base64,${this.formulario.qrcode}`);
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      }
    });
  }

  getDeclaranteFormularioDetailByNumeroFormulario(declaranteId: number, numeroFormulario: string): void {
    this.declaranteService.getFormularioByNumeroFormulario(
      declaranteId, numeroFormulario
    ).subscribe({
      next: (response) => {
        if (this.checkFormularioByEstado(response)) {
          this.formulario = response;
          this.qrcode = this.sanitizer.bypassSecurityTrustUrl(`data:image/jpg;base64,${this.formulario.qrcode}`);
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.router.navigateByUrl('declarante/formulario/draft').then();
        }
      },
      error: (error) => {
        this.spinner.hide();
      }
    });
  }

  checkFormularioByEstado(formulario: Forms): boolean {
    return formulario.estado === Estado.submetido ? true : false;
  }

  download(): void {
    const data = document.getElementById('comprovante');

    if (this.comprovante) {
      html2canvas(this.comprovante.nativeElement).then(canvas => {

        const contentDataURL = canvas.toDataURL('image/jpeg');
        const dd = {
          pagesize: 'A4',
          content: [
            {
              image: contentDataURL,
              width: 310.00,
              height: 497.00,
              style: 'center',
            },
          ],
          styles: {
            center: {
              alignment: 'center' as Alignment
            }
          }
        };
        pdfMake.createPdf(dd).open();
      });
    }

  }

}
