import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Periodo } from 'src/app/core/enums/periodo.enum';
import { Step } from 'src/app/core/enums/step.enum';
import { AgregadoFamiliar } from 'src/app/core/models/agregado-familiar';
import { Forms } from 'src/app/core/models/formulario/forms';
import { Rendimentos } from 'src/app/core/models/formulario/secoes/rendimentos/rendimentos';
import { Documento } from '../../../core/models/documento';

@Component({
  selector: 'app-print-page',
  templateUrl: './print-page.component.html',
  styleUrls: ['./print-page.component.css']
})
export class PrintPageComponent implements OnInit, AfterViewInit {
  formulario: Forms | null | undefined;
  declaranteId!: number;
  dataSourceAgregado: any;
  dataSourceEntidadeEmprestimo: any;
  agregadoFamiliarList!: AgregadoFamiliar[];
  agragadoColumns: string[];
  entidadeColumns: string[];
  rendimentos!: Rendimentos;
  stepChecked = Step.checked;
  rendimentoDocList: Documento[] = [];
  patrimonioDocList: Documento[] = [];
  veiculoDocList: Documento[] = [];
  objetoDocList: Documento[] = [];
  investimentoDocList: Documento[] = [];
  investimentoInterpostaDocList: Documento[] = [];
  participacoesDocList: Documento[] = [];
  participacoesInterpostaDocList: Documento[] = [];
  saldoDocList: Documento[] = [];
  dividasDocList: Documento[] = [];
  prendasDocList: Documento[] = [];
  viagensDocList: Documento[] = [];
  membroDocList: Documento[] = [];
  participacaoDocList: Documento[] = [];
  isDownloading = false;
  inicial = Periodo.inicial;
  anual = Periodo.anual;
  final = Periodo.final;
  posExercicio = Periodo.posExercicio;
  triAnual = Periodo.trianual;
  adHoc = Periodo.adHoc;
  iniciativa = Periodo.mudancaSubstancial;
  data: any;

  constructor(
    private location: Location,
    private cdRef: ChangeDetectorRef,
  ) {
    this.agragadoColumns = ['index', 'nomeCompleto', 'relacao', 'estadoCivil', 'tipoDocumento', 'numeroDocumento', 'entidade', 'atividade'];
    this.entidadeColumns = ['nomeCompleto', 'tipoDocumento', 'numeroDocumento'];
  }

  ngOnInit(): void {
    this.data = history.state;
    if (this.data.formulario) {
      this.formulario = this.data.formulario;
      if (this.formulario?.periodo !== Periodo.mudancaSubstancial) {
        this.rendimentos = this.data.formulario.rendimentoList[0];
        this.agregadoFamiliarList = this.data.agregadoFamiliar;
        this.dataSourceEntidadeEmprestimo = new MatTableDataSource(this.rendimentos.entidadeEmprestimoList);
        this.dataSourceAgregado = new MatTableDataSource(this.agregadoFamiliarList);
      }
    } else {
      this.location.back();
    }
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {
    setTimeout(() => window.print(), 500);
  }

}
