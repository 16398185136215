import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {RecaptchaComponent} from 'ng-recaptcha';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthenticationService} from 'src/app/core/services';
import {environment} from 'src/environments/environment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  private querySubscription!: Subscription;
  hide = true;
  showAlert = false;
  alertMessages: string | undefined;
  form: FormGroup;
  siteKey = environment.captcha;
  disableLogarButton = true;
  reCaptcha: string | undefined;
  @ViewChild('captchaRef') captchaRef: RecaptchaComponent | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private authService: AuthenticationService,
    private router: Router,
  ) {
    this.form = this.formBuilder.group({
      username: ['', [Validators.required, Validators.minLength(4)]],
      senha: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.querySubscription?.unsubscribe();
  }

  resolved(captchaResponse: string, form: FormGroup): void {
    if (!captchaResponse) {
      this.form.reset();
      this.captchaRef?.reset();
    } else {
      this.reCaptcha = captchaResponse;
      this.captchaRef?.reset();
      this.auth(form);
    }
  }

  auth(form: FormGroup): void {
    if (!form.valid) {
      this.showAlert = true;
      this.alertMessages = 'Deve-se preencher todos os campos';
      return;
    }
    this.spinner.show();
    const user = form.value;
    user.reCaptcha = this.reCaptcha;
    this.querySubscription = this.authService.loginUser(user).subscribe(
      () => {
        this.router.navigate((['/admin/painel']))
          .then(() => {
            this.captchaRef?.reset();
            this.spinner.hide();
          });
      },
      (error) => {
        this.captchaRef?.reset();
        this.showAlert = true;
        this.alertMessages = error.message;
        this.spinner.hide();
        this.form.reset();
      }
    );
  }

}
